import React from 'react'
import ServiceSidebar from './ServiceSidebar'
import details1 from '../../assets/images/services/services-details1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">AI & ML Development</span>
                            <h3>About this Services</h3>
                            <p>Improve Machine Learning algorithms by studying underfitting, overfitting, training, validation, n-fold cross validation, testing, and how hyperparameters could improve performance. Perform linear and logistic regressions in Python.</p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                    <div className="content">
                                        <h3>1. Embedded Development Algorithms & Techniques
                                        </h3>
                                        <ul>
                            <li>Finite State Machines (FSM):  Used for designing control logic in embedded systems, especially in real-time applications.
                            </li>

                            <li>Digital Signal Processing (DSP) Algorithms: Applied in embedded systems for audio, video, and sensor data processing in real time.

                            </li>
                            <li>PID Control (Proportional-Integral-Derivative):  A control loop mechanism widely used in industrial control systems for regulating temperature, speed, and other variables.
                            </li>
                            <li>Error Detection and Correction (ECC) Algorithms: Techniques like CRC (Cyclic Redundancy Check) and Hamming Code are used for data integrity in embedded communication systems.

                            </li>
                            <li>Task Scheduling Algorithms: Scheduling methods like Round-Robin, Priority Scheduling, and Earliest Deadline First (EDF) for real-time task management.
                            </li>
                            <li>Memory Management Techniques:  Efficient use of limited resources through memory pooling, heap management, and dynamic memory allocation.
                            </li>

                                        </ul>
                                    </div>
                                </div>

                                



 






                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3> 2. Technologies Used in Embedded Systems Development
                                        </h3>
                                        <ul>
    

<li>Real-Time Operating Systems (RTOS): Such as FreeRTOS, VxWorks, and QNX, providing real-time capabilities for embedded applications.
</li>
<li>Embedded C/C++ Programming: Low-level programming languages for direct hardware control and performance optimization.
</li>
<li>Embedded Linux: Open-source operating system for more complex embedded devices, providing scalability and robustness.
</li>
<li>FPGA (Field-Programmable Gate Array): Used in custom hardware solutions for high-performance computing and signal processing.
</li>
<li>Microcontroller (MCU) and Microprocessor (MPU) Development: Programming and optimization of MCUs and MPUs from manufacturers like ARM, PIC, and AVR for various embedded applications.
</li>
<li>Middleware Solutions:  Interface layers that enable communication between the operating system and applications in embedded systems (e.g., CAN, LIN, and I2C middleware).
</li>



                                        </ul>
                                    </div>
                                </div>

                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                               3. Industry Standards for Embedded Systems


                                        </h3>
                                        <ul>
                              <li> MISRA C/C++: A set of coding standards for the development of safe, reliable software in critical systems like automotive and aerospace.
                              </li>
                              <li>ISO 26262: A functional safety standard for automotive embedded systems to ensure the safety of electronic systems in vehicles.
                              </li>
                              <li>IEC 61508: A standard for functional safety of electrical/electronic/programmable electronic safety-related systems, applied in industrial automation.
                              </li>
                              <li>DO-178C: Certification standard for safety-critical embedded software in avionics.
                              </li>
                              <li>AUTOSAR (Automotive Open System Architecture): Standard for automotive embedded software to ensure interoperability and scalability across vehicle platforms.
                              </li>

    
                                        </ul>
                                    </div>
                                </div>

                              

                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        4. Security in Embedded Systems




                                        </h3>
                                        <ul>

<li>Secure Boot: Ensures that only trusted software is loaded during the boot process to prevent unauthorized access.
</li>
<li>Encryption (AES, RSA):  Encryption algorithms used to secure data in embedded systems, especially in IoT and communication devices.
</li>
<li>Hardware Security Modules (HSM): Dedicated hardware to protect encryption keys and sensitive data in embedded systems.
</li>
<li>Secure Firmware Updates: Ensures that over-the-air (OTA) firmware updates are authenticated and secure.
</li>
<li>Access Control Mechanisms: Secure communication protocols like SSL/TLS and authentication methods for embedded devices to prevent unauthorized access.
</li>
<li>Tamper Detection and Protection: Techniques to detect and prevent tampering with embedded devices, such as anti-tamper sensors or self-destruction mechanisms.
</li>



                                        </ul>
                                    </div>
                                </div>



                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        5. Communication Protocols in Embedded Systems



                                        </h3>
                                        <ul>
                          
                                       
<li>Serial Communication (UART, SPI, I2C): Widely used protocols for communication between embedded devices and peripherals.
</li>
<li>CAN Bus (Controller Area Network): A robust vehicle bus standard used in automotive and industrial applications for communication between microcontrollers and devices.
</li>
<li>LIN (Local Interconnect Network): A simpler, lower-cost protocol than CAN, used in automotive for non-critical communications.
</li>
<li>Ethernet:  
Wired communication protocol providing high-speed data transfer, widely used in industrial and automation applications.</li>
<li>USB (Universal Serial Bus):  Standard for wired communication between an embedded system and external peripherals.</li>
<li>RS-232/RS-485: Serial communication standards used in industrial and embedded systems for long-distance communication. </li>

















                                        </ul>
                                    </div>
                                </div>


                     

                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        7. Embedded Hardware Devices and Boards



                                        </h3>
                                        <ul>

<li>Raspberry Pi:  A versatile, low-cost single-board computer widely used in embedded system prototyping and IoT applications.
</li>
<li>Arduino: A popular microcontroller board for developing simple embedded applications and IoT solutions.
</li>
<li>STM32: Microcontroller family from STMicroelectronics, used for real-time processing and control in industrial embedded systems.
</li>
<li>BeagleBone Black:  A low-power, open-source computer for embedded applications requiring high performance and connectivity.
</li>
<li>ESP32:  A microcontroller with built-in Wi-Fi and Bluetooth, used in wireless IoT applications and embedded systems.
</li>
<li>NXP i.MX:  Microprocessors used for automotive, industrial, and multimedia applications in embedded systems.

</li>
<li>Texas Instruments MSP430: A family of microcontrollers optimized for low-power consumption in embedded applications.
</li>
<li>Atmel AVR: Microcontroller family used in a wide variety of embedded and IoT applications, known for its ease of programming and low power consumption.
</li>
<li>Intel Edison: A computing platform for IoT devices that offers Wi-Fi and Bluetooth communication along with computational power.
</li>

                                        </ul>
                                    </div>
                                </div>

                                



                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        8. Technologies for Embedded System Design




                                        </h3>
                                        <ul>

<li>System-on-Chip (SoC): Integrated circuits that combine all components of a computer system on a single chip, including CPU, memory, and I/O ports, used in mobile devices and IoT.
</li>
<li>Field Programmable Gate Arrays (FPGA): Reprogrammable hardware used for high-performance tasks such as signal processing, encryption, and real-time control in embedded systems.
</li>
<li>Digital Signal Processors (DSP):  Specialized microprocessors designed for processing digital signals in real-time applications, such as audio, video, and telecommunications.
</li>
<li>ASIC (Application-Specific Integrated Circuit):  Custom-designed integrated circuits tailored for specific embedded applications, offering high performance and low power consumption.
</li>
<li>Micro-Electro-Mechanical Systems (MEMS): Miniaturized mechanical and electrical elements embedded in chips, used in sensors like accelerometers, gyroscopes, and pressure sensors.
</li>
                                        </ul>
                                    </div>
                                </div>





                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ‘Content here, content here’, making it look like readable English. Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                            <h3>Application Areas</h3>

                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Manufacturing
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Healthcare
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Automobile
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Banking
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Real Estate
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Logistics
                                    </div>
                                </div>
                            </div>

                            <h3>Technologies That We Use</h3>
                            <ul className="technologies-features">
                                <li><span>JavaScript</span></li>
                                <li><span>Python</span></li>
                                <li><span>Java</span></li>
                                <li><span>C/CPP</span></li>
                                <li><span>PHP</span></li>
                                <li><span>Swift</span></li>
                                <li><span>C# (C- Sharp)</span></li>
                                <li><span>Ruby</span></li>
                                <li><span>SQL</span></li>
                            </ul>
                            <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContent