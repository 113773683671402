import React from 'react'
import {Link} from 'gatsby'

const ServicesOne = () => {
    return (
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
                                <Link to="/service-details">
                                Data Analytics and Artificial Intelligence
                                </Link>
                            </h3>
                            <p>
                            We offer Data Analytics and Artificial Intelligence solutions that empower businesses to make smarter decisions, optimize processes, and unlock new growth opportunities.
</p>

                            <Link className="view-details-btn" to="/service-details">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-settings"></i>
                            </div>

                            <h3>
                                <Link to="/iot">
                               Internet Of Things [IOT]
                                </Link>
                            </h3>

                            <p>
                            We provide innovative IoT solutions that connect devices, enhance automation, and enable real-time data insights for smarter decision-making.                                </p>
                            
                            <Link className="view-details-btn" to="/iot">
                            Testing Solutions, Verification & Validation
                            </Link>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-settings"></i>
                            </div>

                            <h3>
                                <Link to="/embedded-dev">
                              Embedded Development and Services
                                </Link>
                            </h3>

                            <p>
                            Our Embedded Development services create high-performance, reliable, and customized solutions for embedded systems across industries.                                </p>
                            
                            <Link className="view-details-btn" to="/embedded-dev">
                            Testing Solutions, Verification & Validation
                            </Link>
                        </div>
                    </div>

                    

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>
   
                            <h3>
                                <Link to="/service-details">
                                Web Development
                                </Link>
                            </h3>

                            <p>
                            We offer full-stack Web Development services to build responsive, scalable, and user-friendly websites and web applications.                                </p>
                            
                            <Link className="view-details-btn" to="/web-app-dev">
                                View Details
                            </Link>
                        </div>
                    </div>



                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>

                            <h3>
                                <Link to="/cloud-services">
                                Cloud Services
                                </Link>
                            </h3>

                            <p>
                            Our Cloud Services deliver secure, scalable, and cost-efficient solutions for seamless data storage, application hosting, and computing.                                </p>
                            
                            <Link className="view-details-btn" to="/cloud-services">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-settings"></i>
                            </div>

                            <h3>
                                <Link to="/service-details">
                              Customized Drone building 
                                </Link>
                            </h3>

                            <p>
                            We specialize in Customized Drone Building, designing high-performance drones tailored to your specific needs and requirements.
</p>
                            
                            <Link className="view-details-btn" to="/drone-services">
                            View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-segmentation"></i>
                            </div>

                            <h3>
                                <Link to="/service-details">
                                App Development
                                </Link>
                            </h3>

                            <p>
                            Our App Development services deliver native and hybrid mobile apps that provide seamless user experiences on both Android and iOS platforms.                                </p>
                           
                            <Link className="view-details-btn" to="/mob-app-dev">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-analytics"></i>
                            </div>

                            <h3>
                                <Link to="/industrial-automation">
                                Industrial Automation 
                                </Link>
                            </h3>

                            <p>
                            We offer cutting-edge Industrial Automation solutions that streamline operations, increase productivity, and reduce costs through intelligent systems.                                </p>
                            
                            <Link className="view-details-btn" to="/industrial-automation">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-settings"></i>
                            </div>

                            <h3>
                                <Link to="/3D-printing-services">
                              3D Printing Services
                                </Link>
                            </h3>

                            <p>
                            Our 3D Printing Services turn your ideas into reality, offering rapid prototyping and customized manufacturing solutions with advanced 3D printing technology.                                </p>
                            
                            <Link className="view-details-btn" to="/3D-printing-services">
                            View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-settings"></i>
                            </div>

                            <h3>
                                <Link to="/testing">
                                Testing Solutions, Verification & Validation
                                </Link>
                            </h3>

                            <p>
                            We provide comprehensive Testing Solutions for various domains, ensuring that your products are verified, validated, and fully optimized for performance and security.                                </p>
                            
                            <Link className="view-details-btn" to="/testing">
                            View Details
                            </Link>
                        </div>
                    </div>


                </div>
            </div>
        </section>
    )
}

export default ServicesOne