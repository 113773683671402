import React from 'react'
import ServiceSidebar from './ServiceSidebar'
import details1 from '../../assets/images/services/services-details1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">AI & ML Development</span>
                            <h3>About this Services</h3>
                            <p>Improve Machine Learning algorithms by studying underfitting, overfitting, training, validation, n-fold cross validation, testing, and how hyperparameters could improve performance. Perform linear and logistic regressions in Python.</p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                    <div className="content">
                                    <h4>                                                                                                                 
                                    1. Customized Drone Building Services

                                    </h4>
                                       
                                        <ul>
                

<h3>
1.1 End-to-End Drone Design and Development

</h3>
<li>
Complete design and development of drones tailored to specific customer requirements, from concept to production.

</li>


<h3>
1.2 Drone Customization Services

</h3>
<li>
Modify existing drones or build from scratch to meet custom specifications such as payload capacity, endurance, and specialized sensors.

</li>


<h3>
1.3 Prototype and Testing Services

</h3>
<li>
Build and test prototypes based on client requirements, ensuring all performance metrics are met before mass production.

</li>



<h3>
1.4 Drone Assembly and Integration

</h3>
<li>
Assemble customized drones with carefully selected components, including motors, batteries, cameras, and sensors, ensuring optimal performance.

</li>



<h3>
1.5 Payload Integration

</h3>
<li>
Custom payload integration for drones, including cameras, LIDAR, thermal imaging sensors, and other specialized equipment based on industry needs.

</li>



<h3>
1.6 After-Sales Support and Maintenance

</h3>
<li>
Provide technical support, troubleshooting, repairs, and upgrades for customized drones after delivery to ensure peak performance.

</li>



<h3>
1.7 Training and Documentation

</h3>
<li>
Offer hands-on training sessions for operating customized drones, as well as user manuals and guides for clients to manage their drones.

</li>



<h3>
1.8 Regulatory Compliance and Certification

</h3>
<li>
Assist with navigating and securing necessary certifications for drone operations, ensuring compliance with aviation laws and regulations (such as FAA, EASA, etc.).

</li>



<h3>
1.9 Drone Software Development

</h3>
<li>
Custom software solutions for flight planning, navigation, autopilot systems, and data analysis tailored to specific customer use cases.

</li>




                                        </ul>
                                    </div>
                                </div>

                                






 






                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">

                                    <h4>                                                                                                                    2. Drone Building Techniques





</h4>
                                        <ul>



<h3>
2.1 CAD and 3D Modeling

</h3>
<li>
Use of advanced CAD software for designing custom drone frames and components, optimizing for weight, aerodynamics, and durability.

</li>

<h3>
2.2 3D Printing for Prototyping

</h3>
<li>
Rapid prototyping using 3D printing technology for creating custom drone parts, ensuring quick iteration during design and testing phases.

</li>

<h3>
2.3 Aerodynamic Optimization

</h3>
<li>
Techniques for optimizing drone design for minimal air resistance and improved flight stability, resulting in increased flight times and efficiency.

</li>


<h3>
2.4 Composite Material Integration

</h3>
<li>
Incorporating lightweight yet strong materials such as carbon fiber and fiberglass into drone builds to improve durability and payload capacity.

</li>


<h3>
2.5 Precision Engineering

</h3>
<li>
Use of CNC machining and other precision techniques to ensure all components are built with high accuracy for seamless integration and performance.

</li>


<h3>
2.6 Electrical and Mechanical Integration

</h3>
<li>
Advanced techniques for integrating electrical components (motors, ESCs, batteries) and mechanical parts (propellers, landing gears) into the drone.

</li>


<h3>
2.7 Battery Optimization and Power Management

</h3>
<li>
Custom power systems designed for optimal flight time and energy efficiency, integrating advanced lithium polymer (LiPo) or lithium-ion batteries.

</li>


<h3>
2.8 Autonomous Flight and Control Systems

</h3>
<li>
Implementation of autopilot systems, including GPS, inertial measurement units (IMU), and onboard processors to enable autonomous flight.

</li>





                                        </ul>
                                    </div>
                                </div>



                                

                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                       
                                   
                                        <h3>
                                        3. Drone Building Tools and Software

                                        </h3>
                                        <ul>
              

<h3>
3.1 CAD Tools (Computer-Aided Design)

</h3>

<li>
AutoCAD, SolidWorks, Fusion 360: Used for creating precise designs of drone frames, components, and enclosures.

</li>



<h3>
3.2 3D Printing Tools

</h3>

<li>
Ultimaker Cura, Simplify3D: Software used for 3D printing custom parts for drone prototypes or final production.

</li>



<h3>
3.3 Flight Control Software

</h3>

<li>
Mission Planner, QGroundControl, PX4: Software platforms used to program and control flight paths, navigation, and autopilot systems.

</li>



<h3>
3.4 Simulation Tools

</h3>

<li>
X-Plane, DroneKit, Gazebo: Simulation software to test drone designs and control algorithms in a virtual environment before real-world deployment.

</li>



<h3>
3.5 Autopilot Firmware

</h3>

<li>
ArduPilot, PX4 Autopilot: Open-source firmware used for autonomous control of drones, including flight stabilization and mission planning.

</li>



<h3>
3.6 Payload Integration Software

</h3>

<li>
Flir Tools (for thermal imaging), Pix4D (for mapping and photogrammetry): Software platforms used to control and integrate specialized drone payloads.

</li>



<h3>
3.7 Battery Monitoring Software
 
</h3>

<li>
BLHeli, Betaflight: Software for monitoring battery health and optimizing power consumption during drone flights.

</li>



<h3>
3.8 Communication and Control Systems

</h3>

<li>
RFLink, LoRa, Wi-Fi: Technologies and software for maintaining a reliable communication link between drones and control stations over long distances.

</li>







    
                                        </ul>
                                    </div>
                                </div>

                              

                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        4. Technologies Used in Drone Building

                                        </h3>
                                    
                                        <ul>


<h3>
4.1 GPS and Navigation Systems

    </h3>
<li>
Integration of advanced GPS modules for precise navigation and waypoint-based flight planning.

</li>



<h3>
4.2 IMU (Inertial Measurement Units)

    </h3>
<li>
Sensors that provide data on the drone’s orientation, velocity, and acceleration for stable flight control.

</li>


<h3>
4.3 Artificial Intelligence and Machine Learning

    </h3>
<li>
AI-driven systems for autonomous navigation, obstacle avoidance, real-time data processing, and decision-making.

</li>


<h3>
4.4 LIDAR and Advanced Sensors

    </h3>
<li>
Integration of LIDAR, infrared, and ultrasonic sensors for obstacle detection, terrain mapping, and environmental awareness.

</li>


<h3>
4.5 High-Efficiency Motors

    </h3>
<li>
Brushless motors optimized for efficiency, endurance, and thrust, allowing drones to carry heavier payloads and fly longer distances.

</li>


<h3>
4.6 Advanced Propulsion Systems

    </h3>
<li>
Use of advanced propeller designs and motor combinations to achieve optimal thrust-to-weight ratios and enhance maneuverability.

</li>


<h3>
4.7 FPV (First Person View) Technology

    </h3>
<li>
FPV cameras and goggles for real-time video transmission, allowing pilots to control drones with a first-person view.

</li>



<h3>
4.8 5G Connectivity

    </h3>
<li>
Use of 5G networks for real-time communication and control of drones over long distances, providing low-latency video streaming and data transfer.

</li>




                                        </ul>
                                    </div>
                                </div>



                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        5. Different Types of Drones We Build

                                        </h3>
                                   
                                        <ul>
                          


<h3>
5.1 Fixed-Wing Drones

</h3>
<li>
Long-endurance drones designed for covering large distances, suitable for mapping, surveillance, and agricultural monitoring.

</li>


<h3>
5.2 Multi-Rotor Drones (Quadcopters, Hexacopters, Octocopters)

</h3>
<li>
Versatile drones used for aerial photography, videography, and industrial inspection, offering excellent stability and control.

</li>


<h3>
5.3 Hybrid VTOL (Vertical Take-Off and Landing) Drones

</h3>
<li>
Drones that combine the benefits of fixed-wing flight with multi-rotor capabilities, offering long-range flight with the flexibility of vertical take-off and landing.

</li>


<h3>
5.4 Racing Drones

</h3>
<li>
High-speed drones designed for competitive racing, built for agility, speed, and real-time FPV performance.

</li>


<h3>
5.5 Agricultural Drones

</h3>
<li>
Customized drones used for crop monitoring, spraying, and precision agriculture, equipped with sensors to analyze plant health and optimize crop management.

</li>



<h3>
5.6 Inspection and Surveying Drones

</h3>
<li>
Drones equipped with specialized cameras and sensors for industrial inspection, surveying, and asset management in industries like oil and gas, construction, and utilities.

</li>


<h3>
5.7 Delivery Drones

</h3>
<li>
Drones designed for transporting goods, with customized payload capacities and delivery mechanisms, used in logistics, e-commerce, and medical supply deliveries.

</li>


<h3>
5.8 Underwater Drones (ROVs)

</h3>
<li>
Remotely operated underwater vehicles for exploring and inspecting submerged structures, pipelines, and marine ecosystems.

</li>


<h3>
5.9 Surveillance and Security Drones

</h3>
<li>
Drones built for security and surveillance tasks, equipped with thermal cameras, night vision, and AI-powered analytics for real-time monitoring.

</li>



<h3>
5.10 Medical Drones

</h3>
<li>
Drones designed for delivering medical supplies, including blood, organs, and medicines to remote or disaster-struck areas.

</li>



                                        </ul>
                                    </div>
                                </div>


                     

                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        6. Drone Lifecycle

                                        </h3>
                           
                                        <ul>


<h3>
6.1 Concept and Requirement Gathering

</h3>
<li>
Understanding client needs and defining the scope, purpose, and technical specifications for the custom drone.

</li>


<h3>
6.2 Design and Prototyping

</h3>
<li>
Creating drone designs using CAD software, developing prototypes through 3D printing, and iterative testing.

</li>


<h3>
6.3 Development and Integration

</h3>
<li>
Assembling the drone and integrating electronic components, sensors, and software systems.

</li>


<h3>
6.4 Testing and Calibration

</h3>
<li>
Performing extensive testing to ensure the drone meets flight, safety, and performance requirements, including stability and payload capability.

</li>


<h3>
6.5 Deployment and Training

</h3>
<li>
Delivering the customized drone to the client and providing necessary training and documentation for operation and maintenance.

</li>


<h3>
6.6 Maintenance and Upgrades

</h3>
<li>
Offering regular maintenance, firmware updates, and part replacements to ensure the longevity and optimal performance of the drone.

</li>




                                        </ul>
                                    </div>
                                </div>

                                



   



                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ‘Content here, content here’, making it look like readable English. Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                            <h3>Application Areas</h3>

                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Manufacturing
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Healthcare
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Automobile
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Banking
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Real Estate
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Logistics
                                    </div>
                                </div>
                            </div>

                            <h3>Technologies That We Use</h3>
                            <ul className="technologies-features">
                                <li><span>JavaScript</span></li>
                                <li><span>Python</span></li>
                                <li><span>Java</span></li>
                                <li><span>C/CPP</span></li>
                                <li><span>PHP</span></li>
                                <li><span>Swift</span></li>
                                <li><span>C# (C- Sharp)</span></li>
                                <li><span>Ruby</span></li>
                                <li><span>SQL</span></li>
                            </ul>
                            <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContent