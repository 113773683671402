import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import process1 from '../../assets/images/process/process1.png'
import process2 from '../../assets/images/process/process2.png'
import process3 from '../../assets/images/process/process3.png'
import process4 from '../../assets/images/process/process4.png'
import process5 from '../../assets/images/process/process5.png'
import process6 from '../../assets/images/process/process6.png'
import shape from '../../assets/images/shape/circle-shape1.png'

const HowItWork = () => {
    return (
        <section className="process-area pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="about" /> 
                        How It's Work
                    </span>
                    {/* <h2>The Data Science Process</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p> */}
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="single-process-box">
                            <div className="number">1</div>
                            <div className="image">
                                <img src={process1} alt="about" />
                            </div>
                            <h3>Understanding the Problem Statement</h3>
                            <p>
                            We begin by thoroughly understanding the core challenges of your business to ensure we address your specific needs effectively.                                </p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-process-box">
                            <div className="number">2</div>
                            <div className="image">
                                <img src={process2} alt="about" />
                            </div>
                            <h3>Requirement Gathering</h3>
                            <p>
                            Our team works closely with you to gather detailed requirements, ensuring that we capture every aspect of your vision.                                </p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-process-box">
                            <div className="number">3</div>
                            <div className="image">
                                <img src={process3} alt="about" />
                            </div>
                            <h3>Analyze the Requirement</h3>
                            <p>
                            We analyze the gathered requirements to identify key priorities, constraints, and opportunities for the best possible solution.                                </p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-process-box ">
                            <div className="number">4</div>
                            <div className="image">
                                <img src={process4} alt="about" />
                            </div>
                            <h3>Explore the Best Solution</h3>
                            <p>
                            We research and explore the most innovative and efficient solutions tailored to meet your objectives and technical needs.                                </p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-process-box">
                            <div className="number">5</div>
                            <div className="image">
                                <img src={process5} alt="about" />
                            </div>
                            <h3>Plan, Design, and Development</h3>
                            <p>
                            Our structured approach includes meticulous planning, design, and development to ensure that the final solution is robust and scalable.                                </p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-process-box">
                            <div className="number">6</div>
                            <div className="image">
                                <img src={process6} alt="about" />
                            </div>
                            <h3>Communicate Results</h3>
                            <p>We keep you informed at every step, providing transparent updates and ensuring alignment with your expectations.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape} alt="about" />
            </div>
        </section>
    )
}

export default HowItWork