import React from 'react'
import ServiceSidebar from './ServiceSidebar'
import details1 from '../../assets/images/services/services-details1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">AI & ML Development</span>
                            <h3>About this Services</h3>
                            <p>Improve Machine Learning algorithms by studying underfitting, overfitting, training, validation, n-fold cross validation, testing, and how hyperparameters could improve performance. Perform linear and logistic regressions in Python.</p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                    <div className="content">
                                    <h4>                                                                                                                 
                                    1. Mobile App Development Services

                                    </h4>
                                       
                                        <ul>
                

<h3>
1.1 Native Mobile App Development

</h3>
<li>
Development of platform-specific apps for iOS and Android using their native languages and SDKs, ensuring high performance and deep integration with device features.

</li>



<h3>
1.2 Hybrid Mobile App Development

</h3>
<li>
Building cross-platform mobile apps using frameworks like React Native, Flutter, or Ionic, ensuring cost-effective development and a single codebase for multiple platforms.

</li>



<h3>
1.3 Custom Mobile App Development

</h3>
<li>
Tailored app development solutions for unique business needs, ensuring fully customizable features, functionality, and branding.

</li>



<h3>
1.4 UI/UX Design for Mobile Apps

</h3>
<li>
Focused on crafting engaging and intuitive user interfaces and user experiences, with an emphasis on modern design principles and user-centric features.

</li>



<h3>
1.5 Mobile App Testing and QA

</h3>
<li>
Comprehensive testing services, including functional, usability, performance, and security testing, to ensure the app performs smoothly and is free of bugs.

</li>


<h3>
1.6 Mobile App Maintenance and Support

</h3>
<li>
Post-launch app maintenance services to handle updates, bug fixes, feature enhancements, and ensuring compatibility with the latest OS versions.

</li>



<h3>
1.7 Enterprise Mobile App Development

</h3>
<li>
Creating powerful, scalable, and secure mobile apps for enterprises, integrated with back-end systems and designed for efficient business processes.

</li>



<h3>
1.8 E-Commerce Mobile App Development

</h3>
<li>
Developing secure and user-friendly mobile e-commerce applications with features like product catalogs, payment gateways, shopping carts, and customer management systems.

</li>


<h3>
1.9 Progressive Web App (PWA) Development

</h3>
<li>
Development of web apps that function like native mobile apps, providing a seamless user experience on both desktop and mobile platforms without requiring installation.

</li>


<h3>
1.10 Wearable App Development

</h3>
<li>
Designing and developing apps for wearable devices such as smartwatches, fitness trackers, and AR/VR headsets, ensuring seamless integration with mobile apps.

</li>


<h3>
1.11 App Migration and Upgrade Services

</h3>
<li>
Migrating legacy apps to modern platforms or upgrading existing mobile apps to the latest OS versions and frameworks to maintain compatibility and performance.

</li>


<h3>
1.12 Mobile App Consulting Services

</h3>
<li>
Offering expert guidance on mobile app strategy, technology selection, architecture, and best practices to ensure a successful app development process.

</li>






                                        </ul>
                                    </div>
                                </div>

                                






 






                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">

                                    <h4>                                                                                                                  2. Types of Mobile Apps We Develop



</h4>
                                        <ul>



<h3>
2.1 Native Apps

</h3>
<li>
Apps built specifically for a single platform, using native programming languages like Swift for iOS or Kotlin/Java for Android. These apps offer optimal performance and leverage device-specific features.

</li>


<h3>
2.2 Hybrid Apps

</h3>
<li>
Apps developed using cross-platform frameworks like React Native, Flutter, or Xamarin. These apps have a single codebase that can run on multiple platforms (iOS, Android) while maintaining near-native performance.

</li>



<h3>
2.3 Web Apps

</h3>
<li>
Responsive web applications that work on any device with a browser. They do not need installation and can be accessed via URLs.

</li>



<h3>
2.4 Progressive Web Apps (PWAs)

</h3>
<li>
Web applications that provide an app-like experience on mobile devices, offering offline capabilities, push notifications, and faster load times.

</li>


<h3>
2.5 Cross-Platform Apps

</h3>
<li>
Apps built using technologies like Flutter or Xamarin that work across multiple platforms with a single codebase, reducing development costs and time.

</li>


<h3>
2.6 Wearable Apps

</h3>
<li>
Apps developed specifically for wearable devices such as smartwatches, providing features like fitness tracking, notifications, and mobile app integration.

</li>


<h3>
2.7 IoT Mobile Apps

</h3>
<li>
Mobile apps integrated with IoT devices, allowing users to control and monitor smart devices, wearables, or industrial IoT systems directly from their smartphones.

</li>


<h3>
2.8 AR/VR Mobile Apps

</h3>
<li>
Apps that provide augmented reality (AR) and virtual reality (VR) experiences, widely used in gaming, real estate, education, and retail sectors.

</li>


<h3>
2.9 On-Demand Mobile Apps

</h3>
<li>
Apps tailored for businesses providing on-demand services such as ride-hailing, food delivery, housekeeping, and logistics, with real-time tracking and payment integration.

</li>





                                        </ul>
                                    </div>
                                </div>



                                

                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                       
                                   
                                        <h3>
                                        3. Techniques Used in Mobile App Development

                                        </h3>
                                        <ul>
              

<h3>
3.1 Agile Development

</h3>

<li>
Employing agile methodologies to enable iterative development, continuous feedback, and quick adjustments based on changing requirements.

</li>



<h3>
3.2 DevOps and Continuous Integration

</h3>

<li>
Implementing DevOps practices, including automated testing, continuous integration (CI), and continuous delivery (CD), to ensure faster and more reliable app deployment.

</li>






<h3>
3.4 API-First Development

</h3>

<li>
Building mobile apps with a strong emphasis on integrating APIs and backend services for seamless communication between the app and server.

</li>


<h3>
3.5 Mobile-First Development

</h3>

<li>
Prioritizing mobile platforms and small screen sizes in the app design process to ensure optimal performance and usability on mobile devices.

</li>


<h3>
3.6 Performance Optimization

</h3>

<li>
Techniques for minimizing app load times, optimizing memory usage, and ensuring smooth animations and interactions for better user experience.

</li>













    
                                        </ul>
                                    </div>
                                </div>

                              

                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        4. Tools and Software for Mobile App Development

                                        </h3>
                                    
                                        <ul>


<h3>
4.1 Native Development Tools

    </h3>
<li>
Xcode for iOS development.

</li>

<li>
Android Studio for Android development.

</li>



<h3>
4.2 Cross-Platform Development Tools

    </h3>
<li>
Flutter, React Native, Xamarin: Tools for building apps that work across both iOS and Android with a single codebase.

</li>

<h3>
4.3 UI/UX Design Tools

    </h3>
<li>
Sketch, Figma, Adobe XD: Tools for designing user interfaces and experiences with wireframes, prototypes, and interactive mockups.

</li>

<h3>
4.4 Backend and Cloud Integration

    </h3>
<li>
Firebase, AWS Amplify, Google Cloud, Heroku: Platforms that provide back-end infrastructure, user authentication, and real-time database services for mobile apps.

</li>


<h3>
4.5 Testing and Debugging Tools

    </h3>
<li>
Appium, Selenium, TestFlight, Firebase Test Lab: Tools for testing mobile apps across different devices and operating systems, including real-time bug tracking.

</li>


<h3>
4.6 Analytics Tools

    </h3>
<li>
Google Analytics, Firebase Analytics, Mixpanel: Tools for monitoring user behavior, app performance, and metrics to optimize app functionality.

</li>











                                        </ul>
                                    </div>
                                </div>



                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        5. Technologies Used in Mobile App Development

                                        </h3>
                                   
                                        <ul>
                          


<h3>
5.1 Programming Languages

</h3>
<li>
Swift (iOS), Kotlin (Android), Java, Dart (Flutter), JavaScript (React Native), C# (Xamarin).

</li>

<h3>
5.2 Cloud Services

</h3>
<li>
AWS, Google Cloud, Microsoft Azure: Used for hosting mobile app backends, real-time databases, push notifications, and content delivery networks (CDN).

</li>

<h3>
5.3 Database Technologies

</h3>
<li>
SQLite, Realm, Firebase Realtime Database, MongoDB, CoreData: For managing and storing data within mobile apps.

</li>


<h3>
5.4 Mobile Security Technologies

</h3>
<li>
OAuth, JWT, SSL Encryption, Biometric Authentication: Technologies to ensure data security, secure logins, and safe communication between mobile apps and servers.

</li>



<h3>
5.5 Push Notification Services

</h3>
<li>
Firebase Cloud Messaging (FCM), Apple Push Notification Service (APNs): Technologies for sending real-time notifications to mobile app users.

</li>


<h3>
5.6 IoT and Bluetooth Integration

</h3>
<li>
Bluetooth Low Energy (BLE), Zigbee, MQTT: Technologies used to integrate mobile apps with IoT devices for remote control and data transfer.

</li>









                                        </ul>
                                    </div>
                                </div>


                     

                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        6. Domains and Industries Where We Build Mobile Apps

                                        </h3>
                           
                                        <ul>


<h3>
6.1 E-Commerce

</h3>
<li>
Feature-rich mobile applications for e-commerce businesses with integrated shopping carts, payment gateways, and customer management.

</li>


<h3>
6.2 Healthcare

</h3>
<li>
Apps for medical professionals and patients, including telemedicine, health monitoring, appointment scheduling, and prescription management.

</li>


<h3>
6.3 Education and E-Learning

</h3>
<li>
E-learning mobile apps with interactive courses, assessments, quizzes, and gamification for better student engagement.

</li>


<h3>
6.4 Finance and Banking

</h3>
<li>
Secure banking and financial apps with features such as online payments, money transfers, portfolio management, and budgeting.

</li>


<h3>
6.5 Travel and Tourism

</h3>
<li>
Mobile apps for travel bookings, itinerary planning, hotel reservations, real-time navigation, and local recommendations.

</li>


<h3>
6.6 Real Estate

</h3>
<li>
Property listing and management apps for real estate businesses, integrating virtual tours, search filters, and customer inquiries.

</li>


<h3>
6.7 Fitness and Wellness

</h3>
<li>
Mobile fitness apps with workout plans, progress tracking, diet management, and integration with wearable devices like fitness trackers.

</li>


<h3>
6.8 Social Networking

</h3>
<li>
Social media platforms and messaging apps that connect users and facilitate communication, sharing, and networking.

</li>



<h3>
6.9 Logistics and Transportation

</h3>
<li>
Social media platforms and messaging apps that connect users and facilitate communication, sharing, and networking.

</li>

<li>
Mobile apps for fleet management, route optimization, cargo tracking, and on-demand transportation services.

</li>


<h3>
6.10 Gaming

</h3>
<li>
Mobile games across genres including action, puzzle, strategy, and educational games with immersive graphics and engaging gameplay.

</li>


                                        </ul>
                                    </div>
                                </div>

                                



   



                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ‘Content here, content here’, making it look like readable English. Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                            <h3>Application Areas</h3>

                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Manufacturing
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Healthcare
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Automobile
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Banking
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Real Estate
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Logistics
                                    </div>
                                </div>
                            </div>

                            <h3>Technologies That We Use</h3>
                            <ul className="technologies-features">
                                <li><span>JavaScript</span></li>
                                <li><span>Python</span></li>
                                <li><span>Java</span></li>
                                <li><span>C/CPP</span></li>
                                <li><span>PHP</span></li>
                                <li><span>Swift</span></li>
                                <li><span>C# (C- Sharp)</span></li>
                                <li><span>Ruby</span></li>
                                <li><span>SQL</span></li>
                            </ul>
                            <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContent