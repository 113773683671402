import React from 'react'
import ServiceSidebar from './ServiceSidebar'
import details1 from '../../assets/images/services/services-details1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">AI & ML Development</span>
                            <h3>About this Services</h3>
                            <p>Improve Machine Learning algorithms by studying underfitting, overfitting, training, validation, n-fold cross validation, testing, and how hyperparameters could improve performance. Perform linear and logistic regressions in Python.</p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                    <div className="content">
                                    <h4>1. Types of Cloud Services

                                    </h4>
                                        <h3>1.1 Infrastructure as a Service (IaaS)

                                        </h3>
                                        <ul>
                   <li>Compute Resources: Provision of virtual machines, CPUs, GPUs, and storage.
                   </li>
                   <li>Storage Solutions: Object storage, file storage, and block storage services.
                   </li>

                   <li>Networking Services: Virtual networks, load balancers, and firewalls for secure connectivity.
                   </li>
                   <li>Disaster Recovery: Backup, restore, and disaster recovery solutions.
                   </li>
                   <h3>1.2 Platform as a Service (PaaS)


                                    </h3>

<li>Application Development: Integrated environments for developing, testing, and deploying applications.
</li>
<li>Database Management: Managed database services for SQL, NoSQL, and in-memory databases.
</li>
<li>Container Management: Tools for managing containers such as Docker and Kubernetes services.
</li>
<li>API Management: Platforms for developing, managing, and securing APIs.
</li>

<h3>1.3 Software as a Service (SaaS)


                                    </h3>

<li>Business Applications: Pre-built software for CRM, ERP, HR management, and financial services.
</li>
<li>Collaboration Tools: Cloud-based tools for email, document sharing, and project management.
</li>
<li>Analytics and Reporting: Ready-to-use software for data analysis and reporting.
</li>
<li>Content Management Systems: Cloud-hosted solutions for managing websites and digital content.
</li>

<h3>1.4 Function as a Service (FaaS) / Serverless Computing
</h3>

<li>Event-Driven Computing: Run code in response to events, without managing infrastructure.
</li>
<li>Microservices Deployment: Serverless architectures for deploying microservices in the cloud.
</li>

                                        </ul>
                                    </div>
                                </div>

                                






 






                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">

                                    <h4>2. Cloud Service Providers


</h4>
                                        <h3>                                         2.1 Amazon Web Services (AWS)

                                        </h3>
                                        <ul>
    

<li>EC2 (Elastic Compute Cloud): Scalable virtual machine instances for various workloads.
</li>
<li>S3 (Simple Storage Service): Secure and scalable object storage for data archiving and backups.
</li>
<li>S3 (Simple Storage Service): Secure and scalable object storage for data archiving and backups.
</li>
<li>RDS (Relational Database Service): Managed relational databases like MySQL, PostgreSQL, and Oracle.
</li>
<li>Lambda: Serverless compute service for running code without provisioning servers.
</li>
<li>CloudFront: Global content delivery network (CDN) to deliver content with low latency.
</li>


<h3>2.2 Microsoft Azure
</h3>
<li>Azure Virtual Machines: Scalable compute resources for running virtual machines in the cloud.
</li>
<li>Azure Blob Storage: Object storage solution for unstructured data like images, videos, and documents.
</li>
<li>Azure SQL Database: Managed SQL database service with built-in intelligence and scalability.
</li>
<li>Azure Kubernetes Service (AKS): Managed Kubernetes service for deploying and managing containerized applications.
</li>
<li>Azure Active Directory (AAD): Identity and access management service for secure authentication.
</li>

<h3>2.3 Google Cloud Platform (GCP)
</h3>
<li>Compute Engine: Virtual machines running in Google's data centers for various workloads.
</li>
<li>Google Kubernetes Engine (GKE): Managed Kubernetes environment for deploying containers.
</li>
<li>BigQuery: Fully managed, serverless data warehouse for running SQL queries at scale.
</li>
<li>Cloud Storage: Unified object storage service with high availability and performance.
</li>
<li>AI and Machine Learning Services: Cloud-based tools for building, training, and deploying machine learning models.
</li>



                                        </ul>
                                    </div>
                                </div>



                                

                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                       
                                        <h3>                              3. Cloud Storage and Database Services



                                        </h3>
                                        <h3>3.1 Cloud Storage Solutions
                                        </h3>
                                        <ul>
                          
                                        <li>Object Storage (AWS S3, Azure Blob, Google Cloud Storage): For storing unstructured data like multimedia, logs, and backups.
                                        </li>
                                        <li>Block Storage (AWS EBS, Azure Disk, GCP Persistent Disk): High-performance storage for databases and applications.
                                        </li>
                                        <li>File Storage (Amazon EFS, Azure Files): Managed file storage for shared access across virtual machines.
                                        </li>
                                        <h3>3.2 Cloud Database Solutions
                                        </h3>

<li>Managed SQL Databases: Services like Amazon RDS, Azure SQL, Google Cloud SQL for relational database management.
</li>
<li>NoSQL Databases: Managed services for NoSQL databases like DynamoDB, CosmosDB, and Firebase.
</li>
<li>Data Warehousing: Services like AWS Redshift, Google BigQuery, and Azure Synapse Analytics for large-scale data processing.
</li>
<li>In-Memory Databases: Managed Redis and Memcached services for high-speed data processing.
</li>


    
                                        </ul>
                                    </div>
                                </div>

                              

                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        4. Cloud Security Services




                                        </h3>
                                        <h3>4.1 Identity and Access Management (IAM)
                                        </h3>
                                        <ul>

<li>AWS IAM, Azure Active Directory, Google Cloud IAM: Secure user authentication, role-based access control, and multi-factor authentication (MFA) services.
</li>

<h3>4.2 Encryption Services
</h3>
<li>Cloud KMS (Key Management Service): Tools for managing encryption keys used to secure data in the cloud (AWS KMS, Azure Key Vault, GCP KMS).
</li>
<h3>4.3 Firewall and Network Security
</h3>
<li>AWS WAF, Azure Firewall, Google Cloud Armor: Web application firewalls to protect against malicious traffic.
</li>
<li>DDoS Protection: Cloud services like AWS Shield and Azure DDoS Protection to mitigate distributed denial-of-service attacks.
</li>
<h3>4.4 Compliance and Governance
</h3>
<li>AWS Artifact, Azure Security Center, GCP Security Command Center: Tools for managing compliance certifications, security audits, and regulatory requirements.
</li>
                                        </ul>
                                    </div>
                                </div>



                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        5. Cloud Networking and Content Delivery

                                        </h3>
                                        <h3>5.1 Virtual Private Cloud (VPC)
                                        </h3>
                                        <ul>
                          
                                       
<li>AWS VPC, Azure Virtual Network, Google VPC: Private networks within the cloud for securely managing traffic between cloud resources.
</li>
<h3>5.2 Load Balancing
</h3>
<li>Elastic Load Balancer (AWS), Azure Load Balancer, Google Cloud Load Balancing: Services to distribute traffic across multiple virtual machines or containers for high availability.
</li>
<h3>5.3 Content Delivery Networks (CDN)
</h3>
<li>AWS CloudFront, Azure CDN, Google Cloud CDN: Global networks for distributing content to users with low latency and high performance.
</li>


                                        </ul>
                                    </div>
                                </div>


                     

                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        6. Cloud Management and Automation Tools



                                        </h3>
                                        <h3>6.1 Monitoring and Logging
                                        </h3>
                                        <ul>
<li>
AWS CloudWatch, Azure Monitor, Google Stackdriver: Tools for monitoring application performance, logging, and troubleshooting cloud infrastructure.

</li>
<h3>6.2 Infrastructure as Code (IaC)
</h3>
<li>Terraform, AWS CloudFormation, Azure Resource Manager (ARM): Tools for automating the provisioning and management of cloud resources using code.
</li>
<h3>6.3 Auto-Scaling Services
</h3>
<li>AWS Auto Scaling, Azure Autoscale, Google Cloud Autoscaler: Automatically adjusts compute resources based on traffic demand to optimize performance and cost.
</li>

                                        </ul>
                                    </div>
                                </div>

                                



                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        8. Technologies for Embedded System Design




                                        </h3>
                                        <ul>

<li>System-on-Chip (SoC): Integrated circuits that combine all components of a computer system on a single chip, including CPU, memory, and I/O ports, used in mobile devices and IoT.
</li>
<li>Field Programmable Gate Arrays (FPGA): Reprogrammable hardware used for high-performance tasks such as signal processing, encryption, and real-time control in embedded systems.
</li>
<li>Digital Signal Processors (DSP):  Specialized microprocessors designed for processing digital signals in real-time applications, such as audio, video, and telecommunications.
</li>
<li>ASIC (Application-Specific Integrated Circuit):  Custom-designed integrated circuits tailored for specific embedded applications, offering high performance and low power consumption.
</li>
<li>Micro-Electro-Mechanical Systems (MEMS): Miniaturized mechanical and electrical elements embedded in chips, used in sensors like accelerometers, gyroscopes, and pressure sensors.
</li>
                                        </ul>
                                    </div>
                                </div>





                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ‘Content here, content here’, making it look like readable English. Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                            <h3>Application Areas</h3>

                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Manufacturing
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Healthcare
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Automobile
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Banking
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Real Estate
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Logistics
                                    </div>
                                </div>
                            </div>

                            <h3>Technologies That We Use</h3>
                            <ul className="technologies-features">
                                <li><span>JavaScript</span></li>
                                <li><span>Python</span></li>
                                <li><span>Java</span></li>
                                <li><span>C/CPP</span></li>
                                <li><span>PHP</span></li>
                                <li><span>Swift</span></li>
                                <li><span>C# (C- Sharp)</span></li>
                                <li><span>Ruby</span></li>
                                <li><span>SQL</span></li>
                            </ul>
                            <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContent