import React from 'react'
import ServiceSidebar from './ServiceSidebar'
import details1 from '../../assets/images/services/services-details1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">AI & ML Development</span>
                            <h3>About this Services</h3>
                            <p>Improve Machine Learning algorithms by studying underfitting, overfitting, training, validation, n-fold cross validation, testing, and how hyperparameters could improve performance. Perform linear and logistic regressions in Python.</p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                    <div className="content">
                                    <h4>                                        1. 3D Printing Services


                                    </h4>
                                        <h3>1.1 Prototyping


                                        </h3>
                                        <ul>
                
                                        <li>Rapid prototyping for product design validation and functional testing.
                                        </li>
                                        <li>Creation of both low-fidelity and high-fidelity prototypes based on customer specifications.
                                        </li>

                                        <h3>1.2 Custom 3D Printing
                                        </h3>
                                        <li>On-demand 3D printing of parts and products for industries like healthcare, automotive, and consumer goods.
                                        </li>
                                        <li>Customization of prints based on user-submitted CAD files or in-house designs.
                                        </li>

<h3>1.3 Low-Volume Production
</h3>
<li>Small batch manufacturing for low-volume production runs using 3D printing technology.
</li>
<li>Ideal for limited-edition products, custom tools, and spare parts.
</li>

<h3>1.4 3D Scanning and Printing
</h3>
<li>Reverse engineering through 3D scanning of physical objects and replication using 3D printing techniques.
</li>


<h3>1.5 Architectural Models
</h3>
<li>High-detail models for architectural visualizations, including scale models of buildings, landscapes, and urban environments.
</li>


<h3>1.6  Models and Devices
</h3>
<li>Creation of anatomical models for education, training, and surgery preparation.
</li>
<li>Custom medical devices and orthotics based on patient data.
</li>

<h3>1.7 Jewelry and Art Production
</h3>
<li>High-precision 3D printing for custom jewelry, fine art, and decorative pieces.
</li>

<h3>1.8 Education and Training
</h3>
<li>3D printing services for educational institutions for research, student projects, and workshops.
</li>

                                        </ul>
                                    </div>
                                </div>

                                






 






                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">

                                    <h4>                                        2. 3D Printing Techniques


</h4>
                                        <h3>                                  2.1 Fused Deposition Modeling (FDM)


                                        </h3>
                                        <ul>
    
<li>Popular and affordable 3D printing technique for producing functional parts using thermoplastic materials like PLA, ABS, and PETG.
</li>

<h3>2.2 Stereolithography (SLA)
</h3>
<li>High-resolution printing technique using liquid resin and UV lasers to create detailed and smooth surface finishes.
</li>
<li>Ideal for intricate models, prototypes, and jewelry.
</li>

<h3>2.3 Selective Laser Sintering (SLS)
</h3>
<li>Powder-based 3D printing technology using lasers to fuse small particles of polymer, metal, or ceramic powders.
</li>
<li>Suitable for durable, functional prototypes and small production runs.
</li>

<h3>2.4 Digital Light Processing (DLP)
</h3>
<li>Similar to SLA but uses a digital projector screen to flash entire layers at once, offering high-speed and precision printing.
</li>

<h3>2.5 Multi Jet Fusion (MJF)
</h3>
<li>Advanced powder-based 3D printing process used for industrial-grade parts and high-quality prototypes with fine detail and mechanical strength.
</li>

<h3>2.6 Direct Metal Laser Sintering (DMLS)
</h3>
<li>Metal 3D printing technique used to create parts directly from metal powders, ideal for aerospace, automotive, and medical implants.
</li>


<h3>2.7 Binder Jetting
</h3>
<li>A method where a liquid binder is selectively deposited to join powder materials, enabling full-color and multi-material printing.
</li>


<h3>2.8 PolyJet
</h3>
<li>Multi-material 3D printing technology that combines photopolymers and UV light, enabling full-color printing and soft-touch textures.
</li>

<h3>2.9 Electron Beam Melting (EBM)
</h3>
<li>Metal 3D printing technique using electron beams to melt and fuse metal powders for high-performance parts, commonly used in aerospace.
</li>

<h3>2.10 Laminated Object Manufacturing (LOM)
</h3>
<li>A 3D printing technique that bonds layers of material (paper, plastic, or metal) with adhesive and uses a laser to cut them into shape.
</li>



                                        </ul>
                                    </div>
                                </div>



                                

                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                       
                                        <h3>                                                                     3. 3D Printing Tools and Equipment




                                        </h3>
                                        <h3>3.1 3D Printers

                                        </h3>
                                        <ul>
                          
          <li>Desktop 3D Printers: Compact and affordable FDM or SLA printers for personal or small business use.
          </li>

          <li>Industrial 3D Printers: Large-scale printers capable of handling high-performance materials and large parts.
          </li>

<li>Metal 3D Printers: Specialized machines for direct metal printing using technologies like DMLS or EBM.
</li>

<h3>3.2 3D Scanners
</h3>

<li>Handheld 3D Scanners: Portable devices used to capture the 3D geometry of physical objects for reverse engineering or replication.
</li>
<li>Desktop 3D Scanners: Stationary devices for scanning smaller objects with high precision and detail.
</li>

<h3>3.3 Finishing Tools
</h3>
<li>Sanding, painting, and polishing tools for post-processing of 3D printed parts to achieve smooth finishes and desired aesthetics.
</li>

<h3>3.4 Laser Cutters
</h3>
<li>Machines used in combination with 3D printing to cut and engrave designs into materials like wood, metal, and acrylic.
</li>


    
                                        </ul>
                                    </div>
                                </div>

                              

                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        4. 3D Printing Software




                                        </h3>
                                        <h3>4.1 CAD (Computer-Aided Design) Software
                                        </h3>
                                        <ul>
<li>AutoCAD: Industry-standard software for 3D modeling and design.
</li>
<li>SolidWorks: Professional-grade CAD software for designing complex mechanical parts.
</li>
<li>Fusion 360: Cloud-based 3D modeling and design software for engineers and product designers.
</li>
<li>TinkerCAD: Easy-to-use, browser-based CAD software for beginners and educational purposes.
</li>

<h3>4.2 Slicing Software
</h3>
<li>Ultimaker Cura: Open-source slicing software that converts 3D models into instructions for 3D printers.
</li>
<li>PrusaSlicer: Advanced slicing software tailored for Prusa printers but compatible with other FDM printers.
</li>
<li>PreForm: Software used for preparing files for SLA printers, specifically for Formlabs printers.
</li>
<li>Simplify3D: Premium slicing software known for its precision, control, and optimization options.
</li>
<h3>4.3 Simulation Software
</h3>
<li>Ansys: Engineering simulation software used to validate and optimize 3D printed parts for structural integrity and performance.
</li>
<li>Autodesk Netfabb: Software for optimizing 3D printing workflows and running simulations to ensure print accuracy.
</li>

                                        </ul>
                                    </div>
                                </div>



                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        5. Technologies Used in 3D Printing

                                        </h3>
                                        <h3>5.1 Additive Manufacturing

                                        </h3>
                                        <ul>
                          
                     <li>The core technology behind 3D printing, where objects are built by adding material layer by layer.
                     </li>         
                     <h3>5.2 Generative Design
                     </h3>         
<li>An AI-driven design process where software generates optimized designs based on constraints and performance criteria, commonly used in conjunction with 3D printing.
</li>
<h3>5.3 Topology Optimization
</h3>
<li>Technology used to optimize the layout of material within a given space for maximum strength and efficiency, frequently applied in 3D printed parts.
</li>
<h3>5.4 Bioprinting
</h3>
<li>Advanced 3D printing technology that prints biological materials and cells to create tissues, organs, or medical implants.
</li>
<h3>5.5 Hybrid Manufacturing
</h3>
<li>Combines traditional manufacturing techniques (like CNC machining) with 3D printing to create parts with complex geometries and high precision.
</li>



                                        </ul>
                                    </div>
                                </div>


                     

                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        6. Types of 3D Prints



                                        </h3>
                                        <h3>6.1 Functional Prototypes
                                        </h3>
                                        <ul>

<li>3D prints designed for functional testing, performance analysis, and iteration during product development.
</li>

<h3>6.2 End-Use Parts
</h3>
<li>Final, production-quality parts created using durable materials and advanced 3D printing technologies such as SLS, DMLS, and MJF.
</li>

<h3>6.3 Architectural Models
</h3>
<li>Detailed 3D-printed models for showcasing building designs and urban planning concepts in architectural projects.
</li>

<h3>6.4 Artistic and Decorative Pieces
</h3>
<li>Custom 3D-printed sculptures, art pieces, jewelry, and decorative items tailored to individual specifications.
</li>

<h3>6.5 Medical Models
</h3>
<li>3D-printed anatomical models used for surgery planning, medical training, and custom prosthetics.
</li>


<h3>6.6 Educational Models
</h3>
<li>3D-printed objects used in classrooms for teaching complex concepts in subjects like biology, engineering, and architecture.
</li>

<h3>6.7 Miniatures and Figurines
</h3>
<li>Highly detailed 3D-printed miniatures for board games, toys, and collectors.
</li>

<h3>6.8 Automotive Parts
</h3>
<li>3D-printed parts for automotive prototyping, custom car components, and spare parts for vintage or hard-to-source vehicles.
</li>

                                        </ul>
                                    </div>
                                </div>

                                



                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        7. Materials Used in 3D Printing
                                        </h3>
                                        <h3>7.1 Plastics and Polymers

                                        </h3>
                                        <ul>
<li>PLA (Polylactic Acid): A biodegradable thermoplastic used in FDM printing, known for ease of use.
</li>
<li>ABS (Acrylonitrile Butadiene Styrene): A strong, impact-resistant plastic used for durable prototypes and end-use parts.
</li>
<li>PETG (Polyethylene Terephthalate Glycol): A durable, flexible material used for food-safe and high-impact applications.
</li>

<h3>7.2 Resins
</h3>
<li>Standard Resins: Used in SLA and DLP printing for high-detail prototypes with smooth finishes.
</li>
<li>Tough Resins: Durable resins designed for functional testing and end-use parts.
</li>
<li>Dental Resins: Specially formulated for medical and dental applications like orthodontics and surgery guides.
</li>

<h3>7.3 Metals
</h3>
<li>Stainless Steel: Metal powder used in DMLS for strong, corrosion-resistant parts.
</li>
<li>Titanium: Lightweight and strong metal for aerospace, medical, and industrial applications.
</li>
<li>Aluminum: Used for lightweight metal parts requiring high thermal conductivity.
</li>

<h3>7.4 Composite Materials
</h3>
<li>Carbon Fiber Reinforced Filament: Lightweight, strong material used for high-performance applications.
</li>
<li>Wood Filament: PLA-based filament infused with wood fibers for decorative and artistic prints.
</li>


                                        </ul>
                                    </div>
                                </div>





                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ‘Content here, content here’, making it look like readable English. Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                            <h3>Application Areas</h3>

                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Manufacturing
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Healthcare
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Automobile
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Banking
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Real Estate
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Logistics
                                    </div>
                                </div>
                            </div>

                            <h3>Technologies That We Use</h3>
                            <ul className="technologies-features">
                                <li><span>JavaScript</span></li>
                                <li><span>Python</span></li>
                                <li><span>Java</span></li>
                                <li><span>C/CPP</span></li>
                                <li><span>PHP</span></li>
                                <li><span>Swift</span></li>
                                <li><span>C# (C- Sharp)</span></li>
                                <li><span>Ruby</span></li>
                                <li><span>SQL</span></li>
                            </ul>
                            <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContent