import React from 'react'
import ServiceSidebar from './ServiceSidebar'
import details1 from '../../assets/images/services/services-details1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">AI & ML Development</span>
                            <h3>About this Services</h3>
                            <p>Improve Machine Learning algorithms by studying underfitting, overfitting, training, validation, n-fold cross validation, testing, and how hyperparameters could improve performance. Perform linear and logistic regressions in Python.</p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                    <div className="content">
                                    <h4>                                                                                                                 
1. Web Development Services

                                    </h4>
                                       
                                        <ul>
                

<h3>
1.1 Custom Web Application Development

</h3>
<li>
Designing and developing bespoke web applications tailored to business requirements, using modern frameworks and scalable architectures.

</li>


<h3>
1.2 Full Stack Web Development

</h3>
<li>
Expertise in both front-end (React, Angular, Vue) and back-end (Node.js, Python, Java) technologies to deliver complete web solutions.

</li>


<h3>
1.3 E-Commerce Web Development

</h3>
<li>
Creating secure, scalable e-commerce platforms with integrated payment gateways, product catalogs, and order management systems.

</li>


<h3>
1.4 CMS Development

</h3>
<li>
Developing and customizing Content Management Systems (CMS) like WordPress, Drupal, and Joomla for easy website management.

</li>


<h3>
1.5 Enterprise Web Applications

</h3>
<li>
Building complex web applications for large organizations, with features like data analytics, dashboards, role-based access, and integrations with enterprise systems.

</li>







                                        </ul>
                                    </div>
                                </div>

                                






 






                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">

                                    <h4>                                                                                                                
                                    2. Back-End Development



</h4>
                                        <ul>



<h3>
2.1 Server-Side Programming

</h3>
<li>
Languages Used:<br></br>
Java: For building high-performance, scalable applications. <br></br>
Python: Known for its simplicity and wide range of frameworks.<br></br>
Node.js: For fast, event-driven server-side scripting and microservices.<br></br>
PHP: For building dynamic websites and content management systems.<br></br>

</li>


<h3>
2.2 Microservices Architecture

</h3>
<li>
Implementing microservices using technologies like Spring Boot and Node.js to build modular, scalable, and maintainable applications.

</li>


<h3>
2.3 REST and GraphQL API Development

</h3>
<li>
Developing secure and high-performance RESTful and GraphQL APIs to enable seamless communication between different services and front-end applications.

</li>

<h3>
2.4 Frameworks Used

</h3>
<li>

Spring Boot (Java)<br></br>
Django (Python)<br></br>
Express.js (Node.js)<br></br>
Flask (Python)<br></br>
NestJS (Node.js)<br></br>
</li>


<h3>
2.5 ORM and Data Handling

</h3>
<li>
Hibernate and JPA: ORM tools used to manage database operations in Java applications, ensuring easy data access and manipulation.

</li>














                                        </ul>
                                    </div>
                                </div>



                                

                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                       
                                   
                                        <h3>
                                        3. Database Operations

                                        </h3>
                                        <ul>
              

<h3>
3.1 Databases Used

</h3>

<li>
Relational Databases:

</li>
<li>MySQL: Popular open-source relational database known for speed and reliability.
</li>
<li>PostgreSQL: A powerful, open-source database with support for advanced data types.
</li>
<li>NoSQL Databases:
</li>
<li>MongoDB: A flexible, document-based NoSQL database for handling unstructured data.
</li>

<li>Cassandra: A highly scalable NoSQL database for handling large datasets in real-time.
</li>
<h3>

</h3>
3.2 Database Design and Optimization

<li>
Creating efficient database schemas, indexes, and query optimization techniques to improve the performance of large-scale data operations.

</li>







    
                                        </ul>
                                    </div>
                                </div>

                              

                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        4. Continuous Integration and Continuous Deployment (CI/CD)

                                        </h3>
                                    
                                        <ul>


<h3>
4.1 CI/CD Tools

    </h3>
<li>
Jenkins, Travis CI, CircleCI: Automating the integration, testing, and deployment process for faster, error-free releases.

</li>


<h3>
4.2 Containerization and Orchestration

    </h3>
<li>
Docker: Containerizing applications for consistency across environments.

</li>
<li>
Kubernetes: Automating the deployment, scaling, and management of containerized applications.

</li>

<h3>
4.3 Infrastructure as Code (IaC)

    </h3>
<li>
Using Terraform and AWS CloudFormation to define and provision infrastructure, ensuring consistency and scalability.

</li>

















                                        </ul>
                                    </div>
                                </div>



                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        5. Logging and Monitoring

                                        </h3>
                                   
                                        <ul>
                          


<h3>
5.1 Logging Tools

</h3>
<li>
ELK Stack: Elasticsearch, Logstash, Kibana: Used for centralized logging, monitoring, and troubleshooting large-scale applications.

</li>
<li>Graylog: Log management and real-time analytics.
</li>


<h3>
5.2 Monitoring Tools

</h3>
<li>
Prometheus: For real-time application monitoring.

</li>

<li>
Grafana: For creating rich dashboards and visualizing application performance metrics.

</li>












                                        </ul>
                                    </div>
                                </div>


                     

                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        6. Testing

                                        </h3>
                           
                                        <ul>


<h3>
6.1 Automated Testing

</h3>
<li>
Using tools like JUnit, Selenium, and Cypress for automated testing, ensuring the code quality and functionality are maintained throughout development.

</li>



<h3>
6.2 Manual Testing

</h3>
<li>
Thorough functional, usability, and performance testing to catch any bugs or issues that automated tests may miss.

</li>

<h3>
6.3 Performance Testing

</h3>
<li>
Tools like Apache JMeter and Gatling for load and stress testing, ensuring applications can handle heavy traffic without downtime.

</li>




                                        </ul>
                                    </div>
                                </div>




                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        7. Deployment and Maintenance

                                        </h3>
                           
                                        <ul>


<h3>
7.1 Deployment Platforms

</h3>
<li>
AWS, Microsoft Azure, Google Cloud Platform (GCP): Utilizing leading cloud providers for scalable, secure, and reliable application hosting.

</li>
<li>Heroku: A PaaS for easy and quick app deployment.
</li>

<h3>
7.2 Auto-Scaling and Load Balancing

</h3>
<li>
Using AWS Auto-Scaling, Elastic Load Balancing, Kubernetes Horizontal Pod Autoscaler, to handle increased user traffic efficiently.

</li>


<h3>
7.3 Maintenance and Support

</h3>
<li>
Ongoing maintenance services, including bug fixes, security patches, feature enhancements, and performance optimization

</li>





                                        </ul>
                                    </div>
                                </div>




                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        8. Lifecycle of Web Development

                                        </h3>
                           
                                        <ul>



<li>
Requirement Gathering

</li>


<li>
Design and Prototyping

</li>


<li>
Back-End and Database Development

</li>


<li>
Front-End Development

</li>


<li>
Integration and Testing

</li>


<li>
Deployment
</li>


<li>
Monitoring and Maintenance

</li>





                                        </ul>
                                    </div>
                                </div>


                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        9. Large-Scale Data Handling

                                        </h3>
                           
                                        <ul>


<h3>
9.1 Big Data Processing

</h3>
<li>
Using tools like Apache Kafka for real-time data streaming, and Hadoop for large-scale data processing and storage.

</li>


<h3>
9.2 Data Security and Encryption

</h3>
<li>
Implementing data encryption, secure APIs, and privacy protocols to protect sensitive data during processing and transmission.

</li>





                                        </ul>
                                    </div>
                                </div>



                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        10. Technologies and Frameworks

                                        </h3>
                           
                                        <ul>


<h3>
10.1 Front-End Technologies

</h3>
<li>
React.js, Angular, Vue.js: Popular JavaScript frameworks for building interactive and responsive front-end applications.

</li>



<h3>
10.2 Back-End Technologies

</h3>
<li>
Java, Python, Node.js, PHP: Used for server-side scripting and back-end logic.

</li>

<li>
Spring Boot, Express.js, Django: Frameworks for building efficient, scalable back-end applications.

</li>



                                        </ul>
                                    </div>
                                </div>

                                

                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        11. Cloud Services and Platforms

                                        </h3>
                           
                                        <ul>


<h3>
11.1 AWS (Amazon Web Services)

</h3>
<li>
A comprehensive cloud platform offering services like EC2, S3, RDS, and Lambda for scalable hosting, storage, and serverless computing.

</li>


<h3>
11.2 Microsoft Azure

</h3>
<li>
Cloud services offering Azure App Services, Azure Functions, and Azure Kubernetes Service (AKS) for app hosting and microservices management.

</li>


<h3>
11.3 Google Cloud Platform (GCP)

</h3>
<li>
A suite of cloud computing services including Compute Engine, Google Kubernetes Engine, and BigQuery for large-scale data processing and hosting.

</li>




                                        </ul>
                                    </div>
                                </div>




                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        12. Security Practices

                                        </h3>
                           
                                        <ul>


<h3>
12.1 OAuth 2.0 and JWT

</h3>
<li>
Implementing OAuth 2.0 for secure user authentication and JSON Web Tokens (JWT) for secure API communication.

</li>


<h3>
12.2 Security Protocols

</h3>
<li>
Using SSL/TLS encryption for data transmission, and security practices such as firewalls and DDoS protection.

</li>



                                        </ul>
                                    </div>
                                </div>



                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        13. Tools and Technologies

                                        </h3>
                           
                                        <ul>


<h3>
13.1 DevOps Tools

</h3>
<li>
Docker, Kubernetes, Ansible, Terraform: Tools for automating infrastructure setup, app deployment, and scaling.

</li>

<h3>13.2 Full-Stack Development Stacks


</h3>
<li>
MEAN Stack: MongoDB, Express.js, Angular, Node.js.

</li>

<li>
MERN Stack: MongoDB, Express.js, React.js, Node.js.

</li>


<h3>
13.3 Web Application Frameworks

</h3>
<li>
Spring Boot (Java), Django (Python), Express.js (Node.js), Flask (Python): Frameworks for building robust, high-performance back-end systems.

</li>




                                        </ul>
                                    </div>
                                </div>



                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        14. Web Application Types We Develop

                                        </h3>
                           
                                        <ul>


<h3>
14.1 E-Commerce Web Applications

</h3>
<li>
Secure, feature-rich online stores with payment integration, product management, and order tracking.

</li>



<h3>
14.2 Enterprise Web Applications

</h3>
<li>
Scalable web applications for large organizations, focusing on workflow automation, data analytics, and CRM systems.

</li>



<h3>
14.3 Single Page Applications (SPA)

</h3>
<li>
React.js, Angular powered SPAs that offer a fast and seamless user experience with minimal page reloads.

</li>



<h3>
14.4 Progressive Web Apps (PWA)

</h3>
<li>
Web applications that provide an app-like experience on mobile devices, including offline capabilities, push notifications, and faster load times.

</li>



                                        </ul>
                                    </div>
                                </div>





   



                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ‘Content here, content here’, making it look like readable English. Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                            <h3>Application Areas</h3>

                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Manufacturing
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Healthcare
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Automobile
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Banking
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Real Estate
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Logistics
                                    </div>
                                </div>
                            </div>

                            <h3>Technologies That We Use</h3>
                            <ul className="technologies-features">
                                <li><span>JavaScript</span></li>
                                <li><span>Python</span></li>
                                <li><span>Java</span></li>
                                <li><span>C/CPP</span></li>
                                <li><span>PHP</span></li>
                                <li><span>Swift</span></li>
                                <li><span>C# (C- Sharp)</span></li>
                                <li><span>Ruby</span></li>
                                <li><span>SQL</span></li>
                            </ul>
                            <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContent