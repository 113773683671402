import React from 'react'
import ServiceSidebar from './ServiceSidebar'
import details1 from '../../assets/images/services/services-details1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">AI & ML Development</span>
                            <h3>About this Services</h3>
                            <p>1. Machine Learning Algorithms <br />
                            <div className="content">
                                        <h3>Supervised Learning: <br /></h3>
                                        <ul>
                                            <li>Linear Regression: Predicts continuous values.<br /></li>
                                            <li>Logistic Regression: Used for binary classification.<br /></li>
                                            <li>
                                            Decision Trees: Simple, interpretable tree-based models.<br /></li>
                                            <li>TRandom Forest: Ensemble of decision trees.<br /></li>
                                            <li>Support Vector Machines (SVM): Classifies by finding the optimal hyperplane.<br /></li>
                                       
                                            <li>k-Nearest Neighbors (k-NN): Classifies by majority vote among neighbors.<br />
                                            </li>
                                            <li>Naive Bayes: Probabilistic classification based on Bayes' Theorem.<br />
                                            </li>
                                            <li>Gradient Boosting Machines (GBM): Builds models sequentially, optimizing errors.<br />
                                            </li>
                                            <li>XGBoost/LightGBM/CatBoost: Efficient, faster versions of gradient boosting.<br />
                                            </li>
                                        </ul>
                                    </div>


                                    <div className="content">
                                        <h3>Unsupervised Learning:<br /></h3>
                                        <ul>
                                     <li>k-Means Clustering: Groups data into clusters based on similarity.<br />
                                     </li>
                                     <li>Hierarchical Clustering: Builds a hierarchy of clusters.<br />
                                     </li>
                                     <li>DBSCAN: Density-based clustering that detects noise.<br />
                                     </li>

                                     <li>Principal Component Analysis (PCA): Dimensionality reduction technique.<br />
                                     </li>
                                     <li>t-SNE: Reduces high-dimensional data into two or three dimensions for visualization.<br />
                                     </li>
                    

                                        </ul>
                                    </div>


                                    <div className="content">
                                        <h3>Reinforcement Learning:<br />
                                        </h3>
                                        <ul>
                               <li>Q-Learning: Finds the optimal policy by learning the value of actions.<br />
                               </li>
                               <li>Deep Q-Networks (DQN): Combines Q-Learning with neural networks.<br />
                               </li>
                               <li>Policy Gradient Methods: Directly optimize the policy in reinforcement learning.<br />
                               </li>
                    

                                        </ul>
                                    </div>


                                    <div className="content">
                                        <h3>2. Deep Learning Algorithms<br />
                                        <br />
                                        </h3>
                                        <ul>
                             <li>Feedforward Neural Networks (FNN): Basic neural networks without loops.<br />
                             </li>

                             <li>Convolutional Neural Networks (CNN): Used for image classification, detection, and recognition.<br />
                             </li>
                             <li>Recurrent Neural Networks (RNN): Used for sequential data like time-series or text.<br />
                             </li>
                             <li>Long Short-Term Memory Networks (LSTM): A type of RNN that solves long-term dependency problems.<br />
                             </li>
                             <li>Gated Recurrent Units (GRU): A simpler version of LSTM.<br />
                             </li>
                             <li>Autoencoders: Unsupervised networks for dimensionality reduction or generative tasks.<br />
                             </li>
                             <li>Generative Adversarial Networks (GANs): Networks that generate new data by opposing a generator and discriminator.<br />
                             </li>

<li>Transformers: Used in Natural Language Processing (NLP) tasks, e.g., BERT, GPT models.<br />
</li>
    <li>Deep Reinforcement Learning: Combines deep learning with reinforcement learning.<br />
    </li>
                                        </ul>
                                    </div>



                                    <div className="content">
                                        <h3>3. Data Visualization Techniques
                                        <br />
                                    
                                        </h3>
                                        <ul>
             <li>Bar Charts/Column Charts: Used to show comparisons between categories.<br />
             </li>
             <li>Line Charts: Displays trends over time.<br />
             </li>
             <li>Pie Charts/Donut Charts: Represents parts of a whole.<br />
             </li>
             <li>Scatter Plots: Shows the relationship between two variables.<br />
             </li>
             <li>Histograms: Shows the distribution of a single variable.<br />
             </li>
             <li>Heatmaps: Displays data values on a color-coded matrix.<br />
             </li>
             <li>Box Plots: Shows the distribution of data and detects outliers.<br />
             </li>
             <li>Pair Plots: Multiple scatter plots for pairwise variable comparison.<br />
             </li>
             <li>Violin Plots: Combines box plot and KDE to show distribution.<br />
             </li>
             <li>Tree Maps: Displays hierarchical data in nested rectangles.<br />
             </li>
             <li>Geospatial Maps: Used for geographical data visualization (e.g., choropleth maps).<br />
             </li>
                                        </ul>
                                    </div>



                                    <div className="content">
                                        <h3>Hyperparameter Tuning:<br />
                                 
                                    
                                        </h3>
                                        <ul>
             <li>Grid Search: Tries every combination of hyperparameters.<br />
             </li>
             <li>Random Search: Randomly samples hyperparameters to find the best combination.<br />
             </li>
             <li>Bayesian Optimization: Optimizes hyperparameters based on past evaluations.<br />
             </li>
             <li>Automated Machine Learning (AutoML): Automated processes to find the best models and hyperparameters.<br />
             </li>
         

      
                                        </ul>
                                    </div>


                                    <div className="content">
                                        <h3>Feature Engineering:<br />

                                 
                                    
                                        </h3>
                                        <ul>
             <li>Scaling (Standardization/Normalization): Ensures features are on a similar scale.<br />

             </li>
           <li>Dimensionality Reduction (PCA, t-SNE): Reduces the number of input features.<br />
           </li>
           <li>Feature Selection: Selects the most important features using methods like L1 regularization or Recursive Feature Elimination (RFE).<br />
           </li>

      
                                        </ul>
                                    </div>







                                    <div className="content">
                                        <h3>Model Optimization:<br />

                                 
                                    
                                        </h3>
                                        <ul>
           <li>Early Stopping: Stops training when the model's performance stops improving.<br />
           </li>
<li>Learning Rate Scheduling: Dynamically adjusts the learning rate during training.<br />
</li>
    <li>Batch Size Optimization: Adjusts the batch size to improve convergence speed.<br />
    </li>  
                                        </ul>
                                    </div>








5. Validation in Machine Learning<br />


<div className="content">
                                        <h3>Cross-Validation:<br />


                                 
                                    
                                        </h3>
                                        <ul>
   <li>k-Fold Cross-Validation: Splits data into k subsets and trains on k-1, validating on the remaining fold.<br />
   </li>
   <li>Stratified k-Fold: Ensures each fold has an equal proportion of class labels (for classification problems).<br />
   </li>

   <li>Leave-One-Out Cross-Validation (LOO-CV): Uses one instance for validation and the rest for training.<br />
   </li>

   <li>Holdout Method: Splits the dataset into training and testing sets.<br />
   </li>

   <li>Bootstrap Sampling: Randomly samples the dataset with replacement to estimate model performance.<br />
   </li>

                                        </ul>
                                    </div>

                                    6. Model Evaluation Metrics<br />
                                    <div className="content">
                                        <h3>For Classification:<br />


                                 
                                    
                                        </h3>
                                        <ul>
 <li>Accuracy: Proportion of correct predictions.<br />
 </li>
 <li>Precision: True positives / (true positives + false positives).<br />
 </li>
 <li>Recall (Sensitivity): True positives / (true positives + false negatives).<br />
 </li>
 <li>F1 Score: Harmonic mean of precision and recall.<br />
 </li>
 <li>Confusion Matrix: Shows the breakdown of predictions (TP, FP, TN, FN).<br />
 </li>
 <li>ROC-AUC: Area under the ROC curve, representing true positive rate vs. false positive rate.<br />
 </li>
 <li>Log Loss: Measures the uncertainty of predictions (used in probabilistic classifiers).<br />
 </li>
                                        </ul>
                                    </div>


                                    <div className="content">
                                        <h3>For Regression:<br />



                                 
                                    
                                        </h3>
                                        <ul>
<li>Mean Squared Error (MSE): Measures average squared difference between actual and predicted values.<br />
</li>
<li>Root Mean Squared Error (RMSE): Square root of MSE.<br />
</li>
<li>Mean Absolute Error (MAE): Measures the average absolute difference between actual and predicted values.<br />
</li>

<li>R-squared (R²): Proportion of variance explained by the model.<br />
</li>

                                        </ul>
                                    </div>


                                    <div className="content">
                                        <h3>For Clustering:<br />

                                
                                        </h3>
                                        <ul>
<li>Silhouette Score: Measures how similar an object is to its own cluster compared to other clusters.<br />
</li>
<li>Davies-Bouldin Index: Measures the average similarity ratio of each cluster with its most similar cluster.<br />
</li>
<li>Inertia (Sum of Squared Errors): Measures within-cluster variance.
</li>
                                        </ul>
                                    </div>



</p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Important Facts</h3>
                                        <ul>
                                            <li>The Field of Data Science</li>
                                            <li>The Problem</li>
                                            <li>The Solution</li>
                                            <li>The Skills</li>
                                            <li>Statistics</li>
                                            <li>Mathematics</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ‘Content here, content here’, making it look like readable English. Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                            <h3>Application Areas</h3>

                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Manufacturing
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Healthcare
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Automobile
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Banking
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Real Estate
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Logistics
                                    </div>
                                </div>
                            </div>

                            <h3>Technologies That We Use</h3>
                            <ul className="technologies-features">
                                <li><span>Supervised Learning</span></li>
                                <li><span>Unsupervised Learning</span></li>
                                <li><span>Reinforcement Learning</span></li>
                                <li><span>Deep Learning</span></li>
                                <li><span>Data Visualization Techniques</span></li>
                                <li><span>Performance Tuning Techniques</span></li>
                                <li><span>Validation in Machine Learning</span></li>
                                <li><span>Model Evaluation Metrics</span></li>
                                <li><span>Image Classification</span></li>
                            </ul>
                            <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContent