import React from 'react'
import ServiceSidebar from './ServiceSidebar'
import details1 from '../../assets/images/services/services-details1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">AI & ML Development</span>
                            <h3>About this Services</h3>
                            <p>Improve Machine Learning algorithms by studying underfitting, overfitting, training, validation, n-fold cross validation, testing, and how hyperparameters could improve performance. Perform linear and logistic regressions in Python.</p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                    <div className="content">
                                    <h4>                                                                                                                  1. Industrial Automation Software and Services




                                    </h4>
                                        <h3>1.1 Supervisory Control and Data Acquisition (SCADA)
                                        </h3>
                                        <ul>
                
<li>A system that monitors and controls industrial processes and infrastructure from a central location, providing real-time data collection, monitoring, and automation.
</li>

<h3>1.2 Distributed Control System (DCS)
</h3>
<li>A control system where control elements are distributed throughout the system, allowing for decentralized control and automation in large industrial plants.
</li>

<h3>1.3 Programmable Logic Controllers (PLC)
</h3>
<li>Hardware and software used for automating industrial processes, programmed to control machines and systems with high precision and reliability.
</li>

<h3>1.4 Human-Machine Interface (HMI) Software
</h3>
<li>A user interface that connects operators to machines, allowing them to interact with and monitor industrial processes in real-time.
</li>

<h3>1.5 Manufacturing Execution Systems (MES)
</h3>
<li>Software that controls, monitors, and tracks production on the factory floor, bridging the gap between enterprise systems and production equipment.
</li>

<h3>1.6 Industrial Internet of Things (IIoT) Solutions
</h3>
<li>IoT platforms for connecting, monitoring, and analyzing data from industrial machinery and devices to optimize performance and predictive maintenance.
</li>

<h3>1.7 Robotics and Automation Software
</h3>
<li>Software solutions for controlling robotic arms, conveyors, and automated guided vehicles (AGVs), enabling automation of material handling and manufacturing.
</li>

<h3>1.8 Simulation Software
</h3>
<li>Tools used to simulate industrial processes, production lines, and control systems to optimize design, planning, and efficiency before actual deployment.
</li>

<h3>1.9 Data Analytics for Industrial Automation
</h3>
<li>Advanced analytics platforms that collect and process data from industrial systems to improve operational efficiency, predict machine failures, and enhance productivity.
</li>

<h3>1.10 Cloud-Based Industrial Automation
</h3>
<li>Automation solutions hosted on cloud platforms, enabling remote monitoring, data storage, analytics, and machine learning-driven optimization.
</li>

<h3>1.11 Energy Management Systems (EMS)
</h3>
<li>Software for monitoring and optimizing the use of energy in industrial environments, reducing energy consumption and improving sustainability.
</li>



















                                        </ul>
                                    </div>
                                </div>

                                






 






                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">

                                    <h4>                                                                                                                     2. Industrial Automation Technologies




</h4>
                                        <h3>                       2.1 Artificial Intelligence (AI) and Machine Learning (ML)




                                        </h3>
                                        <ul>

                                            <li>Use of AI and ML to enhance automation, predictive maintenance, and process optimization through data-driven decision-making.
                                            </li>


<h3>2.2 Cyber-Physical Systems (CPS)
</h3>
<li>Integrated systems combining physical equipment and computational systems, enabling intelligent control and coordination in industrial environments.
</li>

<h3>2.3 Industrial Internet of Things (IIoT)
</h3>
<li>A network of connected industrial devices and sensors that collect and share data to enable real-time monitoring, optimization, and predictive maintenance.
</li>

<h3>2.4 Robotics and Autonomous Systems
</h3>
<li>Advanced robotics used in industrial automation for tasks like material handling, welding, assembly, and quality inspection.
</li>

<h3>2.5 Additive Manufacturing (3D Printing)
</h3>
<li>The use of 3D printing for prototyping, tooling, and even production of components, enabling rapid production cycles and reducing material waste.
</li>


<h3>2.6 Digital Twin Technology
</h3>
<li>Creating virtual models of physical systems to simulate, monitor, and optimize processes in real time, allowing for predictive maintenance and process improvements.
</li>

<h3></h3>
<li></li>


<h3></h3>
<li></li>

<h3>2.7 Edge Computing
</h3>
<li>Processing data at the edge of the network, closer to the industrial equipment, reducing latency and improving real-time decision-making in automation systems.
</li>

<h3>2.8 Cloud Computing
</h3>
<li>Hosting and managing automation systems on cloud platforms for scalable data storage, analytics, and system control.
</li>





<h3>2.9 Industrial Communication Protocols
</h3>
<li>Protocols such as Modbus, Profibus, EtherNet/IP, and Profinet that allow for seamless communication between industrial devices, sensors, and control systems.
</li>


<h3>2.10 Advanced Process Control (APC)
</h3>
<li>Techniques used to enhance the control and stability of complex industrial processes through predictive and adaptive control algorithms.
</li>










                                        </ul>
                                    </div>
                                </div>



                                

                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                       
                                        <h3>                                                                                                           

                                        </h3>
                                        <h3>3. Industrial Automation Hardware

                                        </h3>
                                        <ul>
              

<h3>3.1 Programmable Logic Controllers (PLC)
</h3>
<li>Ruggedized computers used for industrial automation, capable of controlling machinery, manufacturing lines, and other automated processes.

</li>

<h3>3.2 Robotics

</h3>
<li>Industrial robots used for automation in manufacturing, packaging, assembly, and inspection tasks, increasing precision and efficiency.
</li>

<h3>3.3 Sensors and Actuators
</h3>
<li>Devices that detect changes in physical parameters (temperature, pressure, proximity) and actuate mechanical systems based on those changes.

</li>


<h3>3.4 Industrial Control Panels
</h3>
<li>Hardware systems that house controllers, switches, PLCs, and other automation components, providing centralized control and monitoring for industrial machinery.

</li>

<h3>3.5 Human-Machine Interface (HMI) Panels

</h3>
<li>Interactive displays used by operators to monitor machine statuses and control processes in industrial environments.

</li>


<h3>3.6 Automated Guided Vehicles (AGVs)

</h3>
<li>Self-guided robots that move materials around industrial facilities, enhancing efficiency in material handling and logistics.

</li>

<h3>3.7 Industrial Robots and Cobots

</h3>
<li>Industrial Robots: Used for heavy-duty, repetitive tasks such as welding, painting, and assembly.

</li>

<li>Collaborative Robots (Cobots): Work alongside human workers for shared tasks, enhancing safety and efficiency in production environments.
</li>

<h3>3.8 Drives and Motion Control Systems

</h3>
<li>Hardware and systems that control the movement and speed of machinery, enabling precise control over manufacturing processes.

</li>




    
                                        </ul>
                                    </div>
                                </div>

                              

                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        4. Industrial Automation Lifecycle
          
                                        </h3>
                                    
                                        <ul>


<h3>4.1 Requirement Analysis</h3>
<li>Gathering detailed specifications and understanding the automation needs of the industrial environment.

</li>

<h3>
4.2 System Design

</h3>
<li>
Designing the architecture, selecting hardware and software, and planning control strategies.

</li>


<h3>
4.3 Development and Integration

</h3>
<li>
Implementing the designed automation systems, integrating hardware, and developing software controls.

</li>



<h3>
4.4 Testing and Commissioning

</h3>
<li>
Testing the system under real operational conditions, ensuring that it meets performance and safety requirements.

</li>



<h3>
4.5 Operation and Monitoring

</h3>
<li>
Continuous monitoring and control of industrial automation systems during production to ensure optimal performance.

</li>



<h3>
4.6 Maintenance and Upgrades

</h3>
<li>
Routine maintenance, troubleshooting, and periodic upgrades to ensure system longevity and adaptation to new technologies.

</li>


<h3>
4.7 Decommissioning

</h3>
<li>
Safely removing and recycling outdated equipment when it's no longer in use or replaced by newer systems.

</li>













                                        </ul>
                                    </div>
                                </div>



                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        5. Types of Industrial Automation

                                        </h3>
                                   
                                        <ul>
                          


<h3>5.1 Fixed Automation

</h3>
<li>Automation systems designed for high-volume production with limited flexibility, often used in automotive manufacturing.

</li>

<h3>5.2 Programmable Automation

</h3>
<li>Systems that can be reprogrammed to handle different tasks or products, providing flexibility in batch production.

</li>


<h3>5.3 Flexible Automation

</h3>
<li>Advanced automation systems that can be quickly reconfigured for a wide range of products, ideal for industries with varying production needs.

</li>



<h3>5.4 Integrated Automation

</h3>
<li>Fully automated factories where various processes are connected and controlled centrally, often through IIoT and AI technologies.

</li>







                                        </ul>
                                    </div>
                                </div>


                     

                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        6. Domain-Based Automation Solutions



                                        </h3>
                           
                                        <ul>


<h3>6.1 Manufacturing Automation

</h3>
<li>
Solutions designed to automate production lines, material handling, assembly, and quality control in manufacturing industries.

</li>

<h3>6.2 Automotive Automation

</h3>
<li>Automation systems for automotive production, including robotics for welding, painting, and assembly lines.

</li>

<h3>6.3 Oil & Gas Automation

</h3>
<li>Automation in oil extraction, refining, and distribution processes, ensuring efficient control and monitoring of pipelines, rigs, and refineries.

</li>


<h3>6.4 Food & Beverage Automation

</h3>
<li>Automation solutions for packaging, bottling, sorting, and quality control processes in the food and beverage industry.

</li>


<h3>6.5 Pharmaceutical Automation

</h3>
<li>Automation for drug manufacturing, quality assurance, packaging, and regulatory compliance in the pharmaceutical industry.

</li>

<h3>6.6 Energy and Utilities Automation

</h3>
<li>Automation of power generation, distribution, and management systems, ensuring optimal energy use and grid stability.

</li>


<h3>6.7 Healthcare and Medical Device Automation

</h3>
<li>Automating processes for manufacturing medical devices, as well as automation solutions for hospitals and laboratories.

</li>















                                        </ul>
                                    </div>
                                </div>

                                



                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        7. Industrial Automation Techniques
                                        </h3>
                                  
                                        <ul>



<h3>
7.1 Control Theory and Systems

</h3>
<li>
The use of mathematical models to design control systems that regulate the behavior of industrial machines and processes.

</li>

<h3>
7.2 Model Predictive Control (MPC)

</h3>
<li>
Advanced control technique that uses a model of the process to predict future outcomes and optimize control decisions in real-time.

</li>


<h3>
7.3 Six Sigma and Lean Manufacturing

</h3>
<li>
Methodologies that focus on reducing defects, improving process efficiency, and minimizing waste in production environments.

</li>



<h3>
7.4 Agile Automation Development

</h3>
<li>
Using Agile principles to quickly design, test, and implement automation systems with continuous feedback and iteration.

</li>



<h3>
7.5 Total Productive Maintenance (TPM)

</h3>
<li>
A methodology focused on improving the reliability and efficiency of production machinery by emphasizing preventive maintenance and operator involvement.

</li>










                                        </ul>
                                    </div>
                                </div>





                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        8. Automation Testing Tools and Techniques

                                        </h3>
                                      
                                        <ul>



<h3>
8.1 Test Automation for PLCs

    </h3>
<li>
Tools and methods to automate the testing of PLC programs, ensuring correct logic, performance, and fail-safe operation.

</li>

<h3>
8.2 Simulation Tools

    </h3>
<li>
Software that simulates industrial processes and control systems to verify system performance before deployment.

</li>

<h3>
8.3 Performance Testing Tools

    </h3>
<li>
Tools to test the efficiency and reliability of automated systems under different operational conditions, ensuring peak performance.

</li>


<h3>
8.4 Cybersecurity Tools for Industrial Automation

    </h3>
<li>Security testing tools that evaluate the resilience of industrial systems to cyber-attacks and vulnerabilities.


</li>







                                        </ul>
                                    </div>
                                </div>

















                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ‘Content here, content here’, making it look like readable English. Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                            <h3>Application Areas</h3>

                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Manufacturing
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Healthcare
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Automobile
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Banking
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Real Estate
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Logistics
                                    </div>
                                </div>
                            </div>

                            <h3>Technologies That We Use</h3>
                            <ul className="technologies-features">
                                <li><span>JavaScript</span></li>
                                <li><span>Python</span></li>
                                <li><span>Java</span></li>
                                <li><span>C/CPP</span></li>
                                <li><span>PHP</span></li>
                                <li><span>Swift</span></li>
                                <li><span>C# (C- Sharp)</span></li>
                                <li><span>Ruby</span></li>
                                <li><span>SQL</span></li>
                            </ul>
                            <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContent