import React from 'react'
import ServiceSidebar from './ServiceSidebar'
import details1 from '../../assets/images/services/services-details1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">AI & ML Development</span>
                            <h3>About this Services</h3>
                            <p>Improve Machine Learning algorithms by studying underfitting, overfitting, training, validation, n-fold cross validation, testing, and how hyperparameters could improve performance. Perform linear and logistic regressions in Python.</p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                    <div className="content">
                                    <h4>                                                                          1. Testing Solutions



                                    </h4>
                                        <h3>1.1 Functional Testing



                                        </h3>
                                        <ul>
                
<li>Ensures that the application functions as expected according to the requirements and specifications.
</li>

<h3>1.2 Performance Testing
</h3>
<li>Evaluates the speed, responsiveness, and stability of an application under various loads.
</li>

<h3>1.3 Security Testing
</h3>
<li>Identifies vulnerabilities and ensures the protection of data against unauthorized access.
</li>

<h3>1.4 Automation Testing
</h3>
<li>Uses automated scripts and tools to perform repetitive tasks to ensure accuracy and save time.
</li>

<h3>1.5 Regression Testing
</h3>
<li>Ensures that recent code changes have not adversely affected the existing functionality.
</li>


<h3>1.6 Compatibility Testing
</h3>
<li>Verifies that the application works as expected across different browsers, devices, operating systems, and network environments.</li>





<h3>1.7 Usability Testing
</h3>
<li>Evaluates the ease of use and user-friendliness of the application for end-users.
</li>


<h3>1.8 Mobile Testing
</h3>
<li>Focuses on testing mobile applications across various devices, OS versions, and networks.
</li>


<h3>1.9 API Testing
</h3>
<li>Ensures that the APIs used in an application work seamlessly and deliver accurate results.
</li>


<h3>1.10 Cloud Testing
</h3>
<li>Verifies cloud-based applications for scalability, security, and performance across different cloud environments.
</li>









                                        </ul>
                                    </div>
                                </div>

                                






 






                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">

                                    <h4>                                                                             2. Verification & Validation Services



</h4>
                                        <h3>                           2.1 Requirements Validation



                                        </h3>
                                        <ul>
<li>Ensures that all project requirements are clearly defined, correct, and meet customer expectations.
</li>

<h3>2.2 Design Verification
</h3>
<li>Confirms that the design phase meets the requirements and can support functionality, scalability, and security.
</li>

<h3>2.3 Code Review & Static Analysis
</h3>
<li>Systematic examination of source code to ensure it follows coding standards and is free of errors or vulnerabilities.
</li>


<h3>2.4 Functional Validation
</h3>
<li>Testing to ensure that the system functions according to the specified requirements.
</li>


<h3>2.5 User Acceptance Testing (UAT)
</h3>
<li>Final validation to ensure the product is ready for release, conducted by end-users or stakeholders.
</li>


<h3>2.6 Hardware-in-the-Loop (HIL) Testing
</h3>
<li>Testing real-time embedded systems using simulated environments to verify correct system behavior.
</li>








                                        </ul>
                                    </div>
                                </div>



                                

                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                       
                                        <h3>                                                                                                           3. Test Life Cycle





                                        </h3>
                                        <h3>3.1 Requirement Analysis

                                        </h3>
                                        <ul>
                          

                          <li>Understand the testing requirements and define test objectives and scope.
                          </li>

<h3>3.2 Test Planning
</h3>
<li>Create a detailed test plan outlining test strategy, resources, timelines, and deliverables.
</li>

<h3>3.3 Test Design
</h3>
<li>Design test cases and test scripts based on functional and non-functional requirements.
</li>

<h3>3.4 Test Environment Setup
</h3>
<li>Set up the required hardware, software, networks, and tools needed for testing.
</li>

<h3>3.5 Test Execution
</h3>
<li>Execute test cases, log defects, and track progress through various cycles of testing.
</li>

<h3>3.6 Test Reporting
</h3>
<li>Generate detailed reports on test results, defects, and system performance to inform stakeholders.
</li>

<h3>3.7 Test Closure
</h3>
<li>After validation, the test cycle is closed, and test metrics are analyzed for further improvements.
</li>







    
                                        </ul>
                                    </div>
                                </div>

                              

                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        4. Different Types of Testing




                                        </h3>
                                        <h3>4.1 Manual Testing

                                        </h3>
                                        <ul>
<li>Testing carried out by human testers without the use of automation tools to find defects in software functionality.
</li>

<h3>4.2 Automation Testing</h3>
<li>Use of automated tools and scripts to perform repetitive tasks like regression and load testing.
</li>

<h3>4.3 Unit Testing
</h3>
<li>Testing individual components or units of a software application to verify that each part functions correctly.
</li>

<h3>4.4 Integration Testing
</h3>
<li>Testing how different modules or components of the system interact with each other.
</li>


<h3>4.5 System Testing
</h3>
<li>End-to-end testing of the complete application, including all integrated modules, to ensure it meets the overall system requirements.
</li>


<h3>4.6 Acceptance Testing
</h3>
<li>Ensures that the application meets the required standards and functionality for delivery.
</li>


<h3>4.7 Load Testing
</h3>
<li>Evaluates the application's behavior under expected or peak load conditions.
</li>

<h3>4.8 Stress Testing
</h3>
<li>Tests the system's robustness and ability to handle extreme conditions beyond normal operational capacity.
</li>


<h3>4.9 Penetration Testing
</h3>
<li>Simulates cyber-attacks to identify vulnerabilities and ensure that the application is secure from potential threats.
</li>


                                        </ul>
                                    </div>
                                </div>



                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        5. Domain-Based Testing

                                        </h3>
                                        <h3>5.1 IoT Testing


                                        </h3>
                                        <ul>
                          
<li>Validation of device functionality, network connectivity, data integrity, and performance across various IoT platforms and hardware.
</li>

<h3>5.2 Embedded Testing
</h3>
<li>Verification of software and hardware integration in embedded systems, including real-time performance and memory efficiency.
</li>


<h3>5.3 Semiconductor Testing
</h3>
<li>Focuses on validating the functionality, performance, and reliability of semiconductor chips and ICs used in various devices.
</li>


<h3>5.4 Industry-Specific Testing
</h3>
<li>Custom testing solutions for industries like automotive, aerospace, healthcare, manufacturing, and consumer electronics.
</li>


<h3>5.5 Web Application Testing
</h3>
<li>Verifies that web applications perform optimally across browsers, devices, and environments.
</li>


<h3>5.6 Cloud Testing
</h3>
<li>Testing cloud-hosted applications for scalability, performance, data integrity, and security in different cloud environments.
</li>



<h3>5.7 Android & iOS Application Testing
</h3>
<li>Testing mobile applications for functionality, compatibility, and performance on Android and iOS platforms.
</li>


<h3>5.8 Security Testing
</h3>
<li>Includes vulnerability scanning, penetration testing, risk assessment, and authentication testing to secure systems and data.
</li>



                                        </ul>
                                    </div>
                                </div>


                     

                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        6. Automation Testing



                                        </h3>
                                        <h3>
                                        6.1 Selenium
                                        </h3>
                                        <ul>
<li>A widely used open-source tool for automating tests on web applications across different browsers and platforms.
</li>

<h3>6.2 JUnit
</h3>
<li>A unit testing framework for Java applications, facilitating regression testing and validation of software functionality.
</li>

<h3>6.3 TestNG
</h3>
<li>A testing framework for Java that supports testing annotations, parallel execution, and integration with automation tools like Selenium.
</li>

<h3>6.4 Appium
</h3>
<li>An open-source tool for automating native, mobile web, and hybrid applications on Android and iOS platforms.
</li>

<h3>6.5 Cucumber
</h3>
<li>A tool for Behavior-Driven Development (BDD) that allows writing tests in a human-readable format for better collaboration.
</li>

<h3>6.6 Katalon Studio
</h3>
<li>A powerful test automation solution for web, mobile, API, and desktop applications, with easy setup and reporting capabilities.
</li>




                                        </ul>
                                    </div>
                                </div>

                                



                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        7. Testing Techniques
                                        </h3>
                                        <h3>7.1 Black Box Testing

                                        </h3>
                                        <ul>


<li>Testing without knowledge of the internal code structure, focusing on inputs and expected outputs.
</li>

<h3>7.2 White Box Testing
</h3>
<li>Testing based on knowledge of the internal logic of the application, focusing on code coverage and internal structures.
</li>

<h3>7.3 Grey Box Testing
</h3>
<li>Combines both black-box and white-box testing approaches, testing the application with limited knowledge of the internal workings.
</li>

<h3>7.4 Boundary Value Analysis
</h3>
<li>Focuses on testing the values at the boundaries between partitions to uncover edge cases and potential defects.
</li>


<h3>7.5 Equivalence Partitioning
</h3>
<li>Divides input data into equivalent partitions and tests one value from each partition, reducing the number of test cases.
</li>

<h3>7.6 Exploratory Testing
</h3>
<li>Unscripted, informal testing performed by testers to explore the application and find defects through experience and intuition.
</li>



                                        </ul>
                                    </div>
                                </div>





                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        8. Testing Tools and Software

                                        </h3>
                                        <h3>8.1 Selenium

                                        </h3>
                                        <ul>


<li>A tool for automating web browsers, supporting multiple programming languages and platforms.
</li>

<h3>8.2 JMeter</h3>
<li>A performance and load testing tool for web applications and other services, used for measuring system behavior under heavy load.
</li>

<h3>8.3 Postman</h3>
<li>A tool used for API testing to ensure that web services work as expected by validating response data and performance.
</li>

<h3>8.4 Jenkins</h3>
<li>A continuous integration tool used to automate testing processes and ensure that code changes are validated through automated pipelines.
</li>

<h3>8.5 LoadRunner</h3>
<li>A tool for testing performance, enabling testers to simulate user load and identify bottlenecks in the system.
</li>

<h3>
8.6 QTest</h3>
<li>A test management tool that supports various types of testing, including manual, automation, and exploratory testing, with comprehensive reporting.
</li>


<h3>8.7 SoapUI</h3>
<li>A tool for functional and performance testing of web services (REST and SOAP APIs), providing a comprehensive testing platform.
</li>

                                        </ul>
                                    </div>
                                </div>

















                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ‘Content here, content here’, making it look like readable English. Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                            <h3>Application Areas</h3>

                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Manufacturing
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Healthcare
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Automobile
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Banking
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Real Estate
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Logistics
                                    </div>
                                </div>
                            </div>

                            <h3>Technologies That We Use</h3>
                            <ul className="technologies-features">
                                <li><span>JavaScript</span></li>
                                <li><span>Python</span></li>
                                <li><span>Java</span></li>
                                <li><span>C/CPP</span></li>
                                <li><span>PHP</span></li>
                                <li><span>Swift</span></li>
                                <li><span>C# (C- Sharp)</span></li>
                                <li><span>Ruby</span></li>
                                <li><span>SQL</span></li>
                            </ul>
                            <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContent