import React from "react"
// import Navbar from "../components/App/Navbar"
// import Footer from "../components/App/Footer"
// import Layout from "../components/App/Layout"
// import Banner from "../components/Index/Banner"
// import OurSolutions from "../components/Index/OurSolutions"
// import OurServices from "../components/Index/OurServices"
// import OurFeatures from "../components/Index/OurFeatures"
// import TeamMember from "../components/Index/TeamMember"
// import RecentProjects from "../components/Index/RecentProjects"
// import Pricing from "../components/Index/Pricing"
// import Testimonials from "../components/Index/Testimonials"
// import Partner from "../components/Index/Partner"
// import ProjectStartArea from "../components/Index/ProjectStartArea"
// import OurBlog from "../components/Index/OurBlog"

import NavbarTwo from "../components/App/NavbarTwo"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"

import MainBanner from '../components/MachineLearningAISolutions/MainBanner';
import Solutions from '../components/MachineLearningAISolutions/Solutions';
import AboutUs from '../components/MachineLearningAISolutions/AboutUs';
import Services from '../components/MachineLearningAISolutions/Services';
import HowItWork from '../components/MachineLearningAISolutions/HowItWork';
import RecentProjects from '../components/MachineLearningAISolutions/RecentProjects';
import TeamMember from '../components/MachineLearningAISolutions/TeamMember';
import Testimonials from '../components/MachineLearningAISolutions/Testimonials';
import Partner from '../components/MachineLearningAISolutions/Partner';
import OurBlog from '../components/MachineLearningAISolutions/OurBlog';
import ProjectStartArea from '../components/MachineLearningAISolutions/ProjectStartArea';

// import MachineLearningAISolutions from "../components/MachineLearningAISolutions/Ab"


const Home = () => {
  return (
    <Layout>
  <NavbarTwo />
            <MainBanner />
            <Solutions />
            <AboutUs />
            <Services />
            <HowItWork />
            {/* <RecentProjects />
            <TeamMember />
            <Testimonials /> 
            <Partner /> 
            <OurBlog /> */}
            <ProjectStartArea /> 
            <Footer />
    </Layout>
  )
}

export default Home