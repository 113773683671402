import React from 'react'
import ServiceSidebar from './ServiceSidebar'
import details1 from '../../assets/images/services/services-details1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">AI & ML Development</span>
                            <h3>About this Services</h3>
                            <p>Improve Machine Learning algorithms by studying underfitting, overfitting, training, validation, n-fold cross validation, testing, and how hyperparameters could improve performance. Perform linear and logistic regressions in Python.</p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                    <div className="content">
                                        <h3>1. IoT Communication Protocols</h3>
                                        <ul>
                                            <li>MQTT (Message Queuing Telemetry Transport): A lightweight, publish-subscribe messaging protocol designed for low-bandwidth and unreliable networks.
                                            </li>
                                  <li>      CoAP (Constrained Application Protocol): A web-based protocol suited for simple electronics with limited resources, typically used in M2M communication.
                                  </li>
                                  <li>HTTP/HTTPS: Widely used in IoT for web-based communication between devices.
                                  </li>
                                  <li>AMQP (Advanced Message Queuing Protocol): Ensures reliable communication in real-time IoT applications.
                                  </li>
                                  <li>LoRaWAN (Long Range Wide Area Network): A communication protocol optimized for low-power, long-range IoT networks.
                                  </li>
                                  <li>Zigbee: A low-power, low-data-rate protocol used for short-range communication in IoT applications.
                                  </li>
                                  <li>6LoWPAN (IPv6 over Low-Power Wireless Personal Area Networks): Allows for IPv6 communication on low-power devices.
                                  </li>
                                  <li>Bluetooth Low Energy (BLE): Used in IoT for short-range communication and low power consumption.
                                  </li>
                                  <li>Wi-Fi: Commonly used for high-speed IoT device communication.
                                  </li>
                                  <li>NB-IoT (Narrowband IoT): A low-power wide-area network (LPWAN) technology for IoT applications.
                                  </li>
                                  <li>Cellular IoT (LTE-M, 5G): High-bandwidth protocols for long-distance and high-speed IoT communications.
                                  </li>

                                        </ul>
                                    </div>
                                </div>


                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>2. IoT Tools & Platforms
                                        </h3>
                                        <ul>
                            <li>AWS IoT: A cloud platform for IoT device management, security, and data analytics.
                            </li>
                            <li>Microsoft Azure IoT Hub: Facilitates bi-directional communication between IoT applications and devices.
                            </li>

                            <li>Google Cloud IoT Core: Provides device connection and management at scale for IoT projects.
                            </li>
                            <li>IBM Watson IoT: A cognitive platform for connecting devices and systems.
                            </li>

                            <li>ThingSpeak: An open-source platform for IoT applications to collect, analyze, and visualize data.
                            </li>

                            <li>Kaa IoT: A highly flexible open-source IoT platform for device management and data collection.
                            </li>

                            <li>Node-RED: A flow-based tool for wiring together devices and APIs in the IoT ecosystem.
                            </li>

                                        </ul>
                                    </div>
                                </div>

                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                3. IoT Techniques

                                        </h3>
                                        <ul>
                                        <li>Edge Computing: Data processing at the device level, reducing latency and bandwidth usage.
                                        </li>
                                        <li>Fog Computing: Extends cloud computing to the edge of the network for real-time processing in IoT environments.
                                        </li>
                                        <li>Digital Twins: Creating a virtual model of a physical device to monitor its status and simulate future states.
                                        </li>
                                        <li>Predictive Maintenance: Using IoT sensors and data analytics to predict when equipment will need maintenance.
                                        </li>
                                        <li>Data Aggregation: Collecting and summarizing IoT data from multiple devices into actionable insights.
                                        </li>

                                        </ul>
                                    </div>
                                </div>

                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                               4. IoT Communication Standards for Vehicle, Drone, and M2M


                                        </h3>
                                        <ul>
                                  <li>V2X (Vehicle-to-Everything): Communication standard for vehicles to interact with other vehicles, infrastructure, and pedestrians.
                                  </li>
                                  <li>DSRC (Dedicated Short-Range Communications): Used in vehicular communication for safe and efficient transportation.
                                  </li>
                                  <li>5G: Offers high-speed, low-latency connectivity, ideal for autonomous vehicles, drones, and M2M communication.
                                  </li>
                                  <li>4G LTE: Widely used in connected cars and drones for real-time data transmission.
                                  </li>
                                  <li>Wi-Fi 6: Supports high data rates and increased device capacity, making it suitable for IoT applications in vehicles and drones.
                                  </li>
                                  <li>NFC (Near Field Communication): Enables short-range communication for secure transactions between IoT devices.
                                  </li>
                                  <li>UAV-to-UAV Communication (for Drones): Drones communicate via dedicated protocols such as UAVCAN, and MAVLink.
                                  </li>

                                        </ul>
                                    </div>
                                </div>


                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        5. IoT Security Standards and Protocols



                                        </h3>
                                        <ul>
                          
                          <li>Transport Layer Security (TLS): For securing communication between IoT devices and servers.
                          </li>
                          <li>OAuth 2.0: Used for secure access control in IoT applications.
                          </li>
                          <li>Secure Sockets Layer (SSL): Ensures secure transmission of data over the internet for IoT devices.
                          </li>
                          <li>End-to-End Encryption (E2EE): For protecting data integrity and privacy across IoT networks.
                          </li>
                        


                                        </ul>
                                    </div>
                                </div>



                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                        6. IoT Use Cases in Vehicles, Drones, and Machine-to-Machine (M2M)



                                        </h3>
                                        <ul>
                          
                    <li>Connected Vehicles: Vehicle-to-vehicle (V2V) and vehicle-to-infrastructure (V2I) communication for traffic management and autonomous driving.
                    </li>
                    <li>Smart Drones: IoT-enabled drones for logistics, surveillance, and mapping that use real-time communication for navigation and data collection.
                    </li>
                    <li>M2M Communication in Industry 4.0: Machine-to-machine communication in manufacturing processes to monitor equipment, reduce downtime, and improve efficiency.
                    </li>
                    <li>Fleet Management: IoT sensors and GPS systems used to track vehicle locations, monitor driving behavior, and optimize route planning.
                    </li>
                    <li>Remote Monitoring & Control: IoT sensors and controllers used to monitor and control industrial machines remotely for improved safety and efficiency.
                    </li>


                                        </ul>
                                    </div>
                                </div>


                                <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  
                                </div>

                                    <div className="content">
                                        <h3>                                                             
                                   7.     IoT Controllers



                                        </h3>
                                        <ul>
        <li>Raspberry Pi: A popular single-board computer used in a wide range of IoT applications for processing, controlling, and edge computing.
        </li>
        <li>Arduino: Microcontroller platform ideal for simple IoT prototypes and sensor control.
        </li>
        <li>ESP32: A low-cost, low-power microcontroller with built-in Wi-Fi and Bluetooth, used for IoT devices.
        </li>
        <li>STM32: A microcontroller family widely used in IoT and embedded systems for real-time applications.
        </li>
        <li>BeagleBone: A low-power, open-source hardware platform used in IoT applications requiring moderate processing power.
        </li>
        <li>Texas Instruments CC3200: A microcontroller with integrated Wi-Fi, used for secure IoT applications.
        </li>
        <li>NVIDIA Jetson Nano: For edge AI and IoT applications that require high computational power.
        </li>


                                        </ul>
                                    </div>
                                </div>



                                





                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ‘Content here, content here’, making it look like readable English. Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                            <h3>Application Areas</h3>

                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Manufacturing
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Healthcare
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Automobile
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Banking
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Real Estate
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Logistics
                                    </div>
                                </div>
                            </div>

                            <h3>Technologies That We Use</h3>
                            <ul className="technologies-features">
                                <li><span>JavaScript</span></li>
                                <li><span>Python</span></li>
                                <li><span>Java</span></li>
                                <li><span>C/CPP</span></li>
                                <li><span>PHP</span></li>
                                <li><span>Swift</span></li>
                                <li><span>C# (C- Sharp)</span></li>
                                <li><span>Ruby</span></li>
                                <li><span>SQL</span></li>
                            </ul>
                            <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContent