import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import howItWork from '../../assets/images/how-its-work.png'

const WhyChooseUs = () => {
    return (
        <section className="how-its-work-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-content">
                            <span className="sub-title">
                                <img src={starIcon} alt="banner" /> 
                                People Love Us
                            </span>
                            <h2>Why Choose Us?</h2>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p> */}
                            <div className="inner-box">
                                <div className="single-item">
                                    <div className="count-box">
                                        1
                                    </div>
                                    <h3>Expertise Across Multiple Domains</h3>
                                    <p>
                                    With years of experience in Software, Embedded Systems, IoT, and AI, we offer a wide range of specialized solutions tailored to meet your specific needs.                                        
                                    </p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        2
                                    </div>
                                    <h3>Innovative Solutions</h3>
                                    <p>
                                    We leverage the latest technologies and industry best practices to deliver creative, scalable, and future-proof solutions that help your business stay ahead of the competition.
</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        3
                                    </div>
                                    <h3>Customer-Centric Approach</h3>
                                    <p>
                                    We focus on understanding your unique requirements and work collaboratively with you to ensure our solutions align perfectly with your business goals and objectives.
 </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-image">
                            <img src={howItWork} alt="banner" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyChooseUs